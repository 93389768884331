import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batchAPI } from "../api/index.js";
import { toast } from "react-toastify";

export const getBatchSummary = createAsyncThunk(
  "batchSummary/getBatchSummary",
  async (batchId, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await batchAPI.getBatchSummary(batchId);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const batchSummaryInitialState = {
  loading: false,
  batchSummaryResult: null,
};

export const batchSummarySlice = createSlice({
  name: "batchSummary",
  initialState: { ...batchSummaryInitialState },
  reducers: {
    resetBatchSummary: () => batchSummaryInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getBatchSummary.fulfilled]: (state, action) => {
      state.batchSummaryResult = action.payload;
    },
    [getBatchSummary.rejected]: (state, action) => {
      toast.error(`Error getting all batch data, ${action.error.message}`, {
        autoClose: 1000,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetBatchSummary, setLoading } = batchSummarySlice.actions;

export default batchSummarySlice.reducer;
