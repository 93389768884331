import { generateAPIClient } from "./apiClientGenerator";
import { Auth } from "aws-amplify";


export const getSanctionListAPI = async () => {
    const awsApiClient = await generateAPIClient();
  
    let cognitoUser = null;
  
    try {
      cognitoUser = await Auth.currentAuthenticatedUser();
    } catch (err) {
      console.error(err);
      throw Error("Unauthorized access!!!");
    }
  
    return awsApiClient
      .get(
        "/sanctionlist",
        {},
        {},
        {
          headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw Error("Internal Error, check with website owner");
        }
        return response.data;
      });
  };