import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Button, FormHelperText, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resendConfirmationCode,
  setMailSent,
} from "../../../redux/resendConfirmationRedux";
import {
  setEmailId,
  setOpenVerifySignUpModal,
} from "../../../redux/signupRedux";
import {
  NotNullValidation,
  ValidEmail,
  BlacklistEmail
} from "../../utility/validation/validations";
import { validate } from "../../utility/validation/validator";
import ConfirmSignUp from "../confirmSignUp/confirmSignUp";
import "./resendConfirmation.scss";

const ResendConfirmation = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState({
    email: "",
  });
  const validations = {
    email: [NotNullValidation, ValidEmail, BlacklistEmail],
  };
  const mailSent = useSelector((state) => state.resendConfirmation.mailSent);

  const fieldValidator = (value, validations, fieldId) => {
    const { error, errorText } = validate(value, validations);
    setErrorMessage({
      ...errorMessage,
      [fieldId]: "",
    });
    if (error) {
      setErrorMessage({
        ...errorMessage,
        [fieldId]: errorText,
      });
    }
    // setSignUpForm({ ...signUpForm, [fieldId]: value });
    if (fieldId === "email") setEmail(value);
    return error;
  };

  useEffect(() => {
    if (mailSent) {
      dispatch(setOpenVerifySignUpModal(true));
    }
  }, [mailSent]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    backdropFilter: "blur(5px)",
  };

  const handleSubmit = () => {
    dispatch(setMailSent(false));
    const error = fieldValidator(email, validations["email"], "email");
    if (error) {
      console.log(error, "err");
      return;
    }
    dispatch(resendConfirmationCode({ email }));
    dispatch(setEmailId(email));
  };

  const onCloseConfirmSignUp = () => {
    dispatch(setMailSent(false));
  };

  return (
    <>
      <Modal
        id="resendConfirmModal"
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <div
              className="mb-3 back-button d-flex align-items-center"
              onClick={onClose}
            >
              <KeyboardBackspaceOutlinedIcon />
              <span className="button-secondary ms-1 ">BACK</span>
            </div>
            <h3>Resend Confirmation Code</h3>
            <span className="body-small-normal">
              We will send you a verification code <br />
              on the below mentioned email ID.
            </span>
            <TextField
              data-testid="email-input"
              label="Email"
              variant="standard"
              value={email}
              autoComplete="off"
              error={errorMessage["email"] ? true : false}
              onChange={(e) =>
                fieldValidator(e.target.value, validations["email"], "email")
              }
              className="flex-grow-1 w-100 mt-3"
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.code === 13) {
                  handleSubmit();
                }
              }}
            />
            <FormHelperText
              error={errorMessage["email"] ? true : false}
              id="standard-weight-helper-text"
              className="mx-0"
            >
              {errorMessage["email"]}
            </FormHelperText>
            <Button
              data-testid="submit-button"
              variant="contained"
              fullWidth
              onClick={() => handleSubmit()}
              className="submit-button"
            >
              SUBMIT
            </Button>
          </>
        </Box>
      </Modal>
      {mailSent && <ConfirmSignUp onClose={onCloseConfirmSignUp} />}
    </>
  );
};

export default ResendConfirmation;
