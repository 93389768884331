export const NotNullValidation = {
  regex: /([^\s])/,
  message: "Should not be empty",
};

export const NoSpecialCharacter = {
  regex: /^[a-zA-Z0-9\s]*$/,
  message: "Should not contain special character",
};

export const OnlyNumberValidation = {
  regex: /^[0-9]*$/,
  message: "Should contain only numbers",
};

export const ValidEmail = {
  regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  message: "Not a valid email id",
};

export const BlacklistEmail = {
  regex: /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!rediffmail.co.in)(?!hotmail.com)(?!aol.com)(?!aim.com)(?!icloud.com)(?!hubspot.com)(?!tutanota.com)(?!live.com)(?!outlook.com)(?!yahoo.co.in)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/,
  message: "Please enter a valid business email address",
};

export const HasUpperCase = {
  regex: /[A-Z]/g,
  message: "Should contain at least 1 upper case character",
};

export const HasLowerCase = {
  regex: /[a-z]/g,
  message: "Should contain at least 1 lower case character",
};

export const HasNumbers = {
  regex: /[0-9]/g,
  message: "Should contain number",
};

export const HasSpecialCharacter = {
  regex: /[!@#$%^&*(),.?":;[\]\\'=+{/}|<>_`~-]/g,
  message: "Should contain at least 1 special character",
};

export const MinimumEightCharacters = {
  regex: /^(?=.*\d).{8,}$/,
  message: "Should contain minimum of 8 characters",
};

export const MinimumEightDigit = {
  regex: /^(?=.*\d).{8,}$/,
  message: "Should contain minimum of 8 digit",
};

export const MaximumTwoFiftySixCharacters = {
  regex: /^(?=.*\d).{,256}$/,
  message: "Should contain maximum of 256 characters",
};

export const ValidBatchName = {
  regex: /^[A-Za-z0-9 _]+$/,
  message:
    "A batch name can only contains alphabets, numbers, underscore(_) and white spaces",
};
