import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Modal,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	forgotPassword,
	forgotPasswordSubmit,
	setEmailSent,
} from "../../../redux/forgotPassword";
import {
	HasLowerCase,
	HasNumbers,
	HasSpecialCharacter,
	HasUpperCase,
	MinimumEightCharacters,
	NotNullValidation,
	OnlyNumberValidation,
	ValidEmail,
	BlacklistEmail
} from "../../utility/validation/validations";
import { validate } from "../../utility/validation/validator";
import "./forgotPassword.scss";

const ForgotPassword = ({ onClose }) => {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
	const dispatch = useDispatch();
	const emailSent = useSelector((state) => state.forgotPassword.emailSent);
	const [showMail, setShowMail] = useState(false);
	const [errorMessage, setErrorMessage] = useState({
		email: "",
		password: "",
		code: "",
		confirmPassword: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const validations = {
		email: [NotNullValidation, ValidEmail, BlacklistEmail],
		password: [
			NotNullValidation,
			HasUpperCase,
			HasLowerCase,
			HasNumbers,
			HasSpecialCharacter,
			MinimumEightCharacters,
		],
		confirmPassword: [
			NotNullValidation,
			HasUpperCase,
			HasLowerCase,
			HasNumbers,
			HasSpecialCharacter,
			MinimumEightCharacters,
		],
		code: [NotNullValidation, OnlyNumberValidation],
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "#FFFFFF",
		borderRadius: "16px",
		boxShadow: 24,
		p: 4,
	};

	useEffect(() => {
		if (email !== "") {
		  const { error, errorText } = validate(email, validations["email"]);
		  if (error) {
			setErrorMessage({
			  ...errorMessage,
			  email: errorText,
			});
		  }
		}
	  }, [showMail]);
	  
	const formValidator = () => {
		const formValue = {
			email: email,
			password: newPassword,
			confirmPassword: newPasswordConfirm,
			code: code,
		};
		const fields = ["email", "code", "password", "confirmPassword"];
		const errors = {};
		let formError = false;
		for (let index = 0; index < fields.length; index++) {
			const { error, errorText } = validate(
				formValue[fields[index]],
				validations[fields[index]]
			);
			if (error) {
				formError = true;
				errors[fields[index]] = errorText;
			} else {
				errors[fields[index]] = "";
			}
		}
		setErrorMessage(errors);
		return formError;
	};

	const changePassword = () => {
		const error = formValidator();
		if (error) {
			return;
		}
		if (newPassword === newPasswordConfirm) {
			dispatch(forgotPasswordSubmit({ email, code, newPasswordConfirm }));
		} else {
			toast.error(`Passwords do not match`);
		}
	};

	const handleSendMailSubmit = () => {
		const { error, errorText } = validate(email, validations["email"]);
		if (error) {
			setErrorMessage({
				...errorMessage,
				email: errorText,
			});
			return;
		}
		dispatch(forgotPassword({ email }));
	};

	const fieldValidator = (value, validations, fieldId) => {
		const { error, errorText } = validate(value, validations);
		setErrorMessage({
			...errorMessage,
			[fieldId]: "",
		});
		if (error) {
			setErrorMessage({
				...errorMessage,
				[fieldId]: errorText,
			});
		}
		// setSignUpForm({ ...signUpForm, [fieldId]: value });
		if (fieldId === "email") setEmail(value);
		else if (fieldId === "code") setCode(value);
		else if (fieldId === "password") setNewPassword(value);
		else if (fieldId === "confirmPassword") setNewPasswordConfirm(value);
		return error;
	};

	const setVerificationCode = (event) => {
		if (!isNaN(Number(event.target.value))) {
			fieldValidator(event.target.value, validations["code"], "code");
		}
	};

	const setErrorMessageInitialState = () => {
		setErrorMessage({
			email: "",
			password: "",
		});
	};

	return (
		<>
			<Modal
				data-testid= "forgotPassword-Modal"
				id="forgotPassModal"
				open={true}
				onClose={() => {
					dispatch(setEmailSent(false));
					onClose();
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{
					backdropFilter: "blur(15px)",
					background: "rgba(255, 255, 255, 0.2)",
				}}
			>
				<Box sx={style}>
					{!emailSent ? (
						<>
							<div
								className="mb-3 back-button d-flex align-items-center"
								onClick={onClose}
							>
								<KeyboardBackspaceOutlinedIcon />
								<span className="button-secondary ms-1 ">BACK</span>
							</div>
							<h3>Forgot Password</h3>
							<span className="body-small-normal">
								We will send you a verification code <br />
								on the below mentioned email ID.
							</span>
							<TextField
								data-testid="forgotPassword-email"
								label="Email"
								variant="standard"
								value={email}
								onChange={(e) =>
									fieldValidator(e.target.value, validations["email"], "email")
								}
								error={errorMessage["email"] ? true : false}
								className="flex-grow-1 w-100 mt-3"
								onKeyPress={(event) => {
									if (event.key === "Enter") {
										handleSendMailSubmit();
									}
								}}
							/>
							<FormHelperText
								data-testid="forgotPassword-email-error-message"
								error={errorMessage["email"] ? true : false}
								id="standard-weight-helper-text"
								className="mx-0"
							>
								{errorMessage["email"]}
							</FormHelperText>
							<Button
								data-testid="submit-button"
								variant="contained"
								fullWidth
								// onClick={() => dispatch(forgotPassword({ email }))}
								onClick={handleSendMailSubmit}
								className="submit-button"
							>
								SUBMIT
							</Button>
							<div className="d-flex justify-content-between mt-3" data-testid="continue-verification">
								<span className="body-small-normal">
									Already received a code?
								</span>
								{/* <span
									className="ms-1 continue-verification body-small-normal"
									onClick={() => {
										dispatch(setEmailSent(true));
										setShowMail(true);
										setErrorMessageInitialState();
									}}
								>
									Continue Verification
								</span> */}
							</div>
						</>
					) : (
						<>
							<div
								data-testid="back-button"
								className="mb-3 back-button d-flex align-items-center"
								onClick={() => {
									dispatch(setEmailSent(false));
									setShowMail(false);
									setEmail("");
									setErrorMessageInitialState();
								}}
							>
								<KeyboardBackspaceOutlinedIcon />
								<span className="button-secondary ms-1">BACK</span>
							</div>
							<h3>Forgot Password</h3>
							{!showMail ? (
								<span className="body-small-normal">
									We have sent a verification code to: <br />
									<b>{email}</b>
								</span>
							) : (
								<span className="body-small-normal">
									<b>Continue Verification</b>
								</span>
							)}
							{(showMail || !email) && (
								<>
									<TextField
										data-testid = "resetpassword-email"
										label="Email"
										variant="standard"
										value={email}
										onChange={(e) =>
											fieldValidator(
												e.target.value,
												validations["email"],
												"email"
											)
										}
										error={errorMessage["email"] ? true : false}
										className="flex-grow-1 w-100 mt-3"
										onKeyPress={(event) => {
											if (event.key === "Enter") {
												changePassword();
											}
										}}
									/>
									<FormHelperText
										data-testid = "resetpassword-email-error-message"
										error={errorMessage["email"] ? true : false}
										id="standard-weight-helper-text"
										className="mx-0"
									>
										{errorMessage["email"]}
									</FormHelperText>
								</>
							)}
							<TextField
								data-testid="code"
								label="Code"
								variant="standard"
								value={code}
								error={errorMessage["code"] ? true : false}
								onChange={setVerificationCode}
								inputProps={{ maxLength: 6 }}
								className="flex-grow-1 w-100 mt-2"
								onKeyPress={(event) => {
									if (event.key === "Enter") {
										changePassword();
									}
								}}
							/>
							<FormHelperText
								data-testid="code-error-message"
								error={errorMessage["code"] ? true : false}
								id="standard-weight-helper-text"
								className="mx-0"
							>
								{errorMessage["code"]}
							</FormHelperText>
							<FormControl
								error={errorMessage["password"] ? true : false}
								// variant="outlined"
								className="w-100 mt-3"
							>
								<InputLabel className="password-label-margin">
									Password
								</InputLabel>
								<Input
									data-testid="forgotPassword-password"
									type={showPassword ? "text" : "password"}
									value={newPassword}
									error={errorMessage["password"] ? true : false}
									inputProps={{ maxLength: 256 }}
									onChange={(e) =>
										fieldValidator(
											e.target.value,
											validations["password"],
											"password"
										)
									}
									onBlur={(e) =>
										fieldValidator(
											e.target.value,
											validations["password"],
											"password"
										)
									}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											changePassword();
										}
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												data-testid="forgotPassword-password-icon"
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={(e) => e.preventDefault()}
											>
												{showPassword ? (
													<Visibility data-testid="forgotPassword-password-visibility1" sx={{ fontSize: 20 }} />
												) : (
													<VisibilityOff data-testid="forgotPassword-password-visibilityOff" sx={{ fontSize: 20 }} />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								<FormHelperText
									data-testid="forgotPassword-password-error-message"
									error={errorMessage["password"] ? true : false}
									id="standard-weight-helper-text"
									className="mx-0"
								>
									{errorMessage["password"]}
								</FormHelperText>
							</FormControl>

							<FormControl
								error={errorMessage["confirmPassword"] ? true : false}
								// variant="outlined"
								className="w-100 mt-3"
							>
								<InputLabel className="password-label-margin">
									Confirm Password
								</InputLabel>
								<Input
									data-testid="forgotPassword-confirmPassword"
									type={showConfirmPassword ? "text" : "password"}
									value={newPasswordConfirm}
									error={errorMessage["confirmPassword"] ? true : false}
									inputProps={{ maxLength: 256 }}
									onChange={(e) =>
										fieldValidator(
											e.target.value,
											validations["confirmPassword"],
											"confirmPassword"
										)
									}
									onBlur={(e) =>
										fieldValidator(
											e.target.value,
											validations["confirmPassword"],
											"confirmPassword"
										)
									}
									onKeyPress={(event) => {
										if (event.key === "Enter") {
											changePassword();
										}
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												data-testid="forgotPassword-confirmPassword-icon"
												aria-label="toggle password visibility"
												onClick={() =>
													setShowConfirmPassword(!showConfirmPassword)
												}
												onMouseDown={(e) => e.preventDefault()}
											>
												{showConfirmPassword ? (
													<Visibility  data-testid="forgotPassword-confirmPassword-visibility" sx={{ fontSize: 20 }} />
												) : (
													<VisibilityOff data-testid="forgotPassword-confirmPassword-visibilityOff" sx={{ fontSize: 20 }} />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								<FormHelperText
									data-testid="forgotPassword-confirmPassword-error-message"
									id="standard-weight-helper-text"
									className="mx-0"
								>
									{errorMessage["confirmPassword"]}
								</FormHelperText>
							</FormControl>
							<Button
								data-testid="forgotPassword-submit-button"
								variant="contained"
								fullWidth
								onClick={changePassword}
								className="submit-button"
							>
								SUBMIT
							</Button>
						</>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default ForgotPassword;
