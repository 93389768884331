export const StringConstants={
    COMPLETED:"COMPLETED",
    FAILED:"FAILED",
    SCORING:"SCORING",
    PRE_PROCESSING:"PREPROCESSING",
    LOADING:"LOADING",
    COMPLETED_WITH_REJECTS:"COMPLETED WITH REJECTS",
    FAILED_IN_ERROR:"FAILED IN ERROR",
    VALIDATING:"VALIDATING",
    CREATED:"CREATED",
    DOWJONES:"DOWJONES",
    ERROR_SMALL_CASE:"error",
    CSV_SMALL_CASE:"csv",
    JSON_SMALL_CASE:"json"
}

export const capitalizeFirstLetter = (string) => {
    if (string.length > 0)
      return string.charAt(0).toUpperCase() + string.slice(1);
  };
  