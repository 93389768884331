import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batchAPI } from "../api/index.js";
import { toast } from "react-toastify";

export const getAllBatch = createAsyncThunk(
  "batchList/getAllBatch",
  async (userName, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await batchAPI.getAllBatch(userName);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const batchListInitialState = {
  loading: false,
  allBatchData: null,
};

export const batchListSlice = createSlice({
  name: "batchList",
  initialState: { ...batchListInitialState },
  reducers: {
    resetBatchList: () => batchListInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getAllBatch.fulfilled]: (state, action) => {
      state.allBatchData = action.payload;
    },
    [getAllBatch.rejected]: (state, action) => {
      toast.error(`Error getting all batch data, ${action.error.message}`, {
        autoClose: 1000,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetBatchList, setLoading } = batchListSlice.actions;

export default batchListSlice.reducer;
