import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Button, FormHelperText, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmSignUp,
  setOpenVerifySignUpModal,
  setEmailId,
} from "../../../redux/signupRedux";
import { setShowLoginModal } from "../../../redux/loginRedux";
import {
  NotNullValidation,
  OnlyNumberValidation,
  ValidEmail,
  BlacklistEmail
} from "../../utility/validation/validations";
import { validate } from "../../utility/validation/validator";
import "./confirmSignUp.scss";
import { setMailSent } from "../../../redux/resendConfirmationRedux";

const ConfirmSignUp = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    code: "",
  });
  const validations = {
    email: [NotNullValidation, ValidEmail, BlacklistEmail],
    code: [NotNullValidation, OnlyNumberValidation],
  };
  const openModal = useSelector((state) => state.signUp.openVerifySignUpModal);

  const emailId = useSelector((state) => state.signUp.email);

  const fieldValidator = (value, validations, fieldId) => {
    const { error, errorText } = validate(value, validations);
    setErrorMessage({
      ...errorMessage,
      [fieldId]: "",
    });
    if (error) {
      setErrorMessage({
        ...errorMessage,
        [fieldId]: errorText,
      });
    }
    // setSignUpForm({ ...signUpForm, [fieldId]: value });
    if (fieldId === "email") setEmail(value);
    else if (fieldId === "code") setCode(value);
    return error;
  };

  const formValidator = () => {
    const formValue = {
      email: email,
      code: code,
    };
    const fields = ["email", "code"];
    const errors = {};
    let formError = false;
    for (let index = 0; index < fields.length; index++) {
      const { error, errorText } = validate(
        formValue[fields[index]],
        validations[fields[index]]
      );
      if (error) {
        formError = true;
        errors[fields[index]] = errorText;
      } else {
        errors[fields[index]] = "";
      }
    }
    setErrorMessage(errors);
    return formError;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    backdropFilter: "blur(5px)",
  };
  const modalClose = () => {
    dispatch(setMailSent(false));
    dispatch(setOpenVerifySignUpModal(false));
    dispatch(setEmailId(null));
  };
  const handleSubmit = () => {
    const error = formValidator();
    if (error) {
      return;
    }
    dispatch(confirmSignUp({ email: email, code: code }));
    dispatch(setShowLoginModal(true));
  };

  useEffect(() => {
    if (emailId) {
      setEmail(emailId);
    }
  }, []);

  return (
    <>
      <Modal
        data-testid="resend-confirm-modal"
        id="resendConfirmModal"
        open={openModal}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <div
              className="mb-3 back-button d-flex align-items-center"
              onClick={modalClose}
              data-testid="resend-confirm-back-button"
            >
              <KeyboardBackspaceOutlinedIcon />
              <span className="button-secondary ms-1 ">BACK</span>
            </div>
            <h3>Validate Verification Code</h3>
            {!emailId && (
              <TextField
                data-testid="confirmSignup-email"
                label="Email"
                variant="standard"
                value={email}
                autoComplete="off"
                error={errorMessage["email"] ? true : false}
                onChange={(e) =>
                  fieldValidator(e.target.value, validations["email"], "email")
                }
                className="flex-grow-1 w-100 mt-3"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            )}
            <FormHelperText
              data-testid="confirmSignup-email-error-message"
              error={errorMessage["email"] ? true : false}
              id="standard-weight-helper-text"
              className="mx-0"
            >
              {errorMessage["email"]}
            </FormHelperText>
            <TextField
              data-testid="confirmSignup-code"
              label="Code"
              variant="standard"
              value={code}
              autoComplete="off"
              error={errorMessage["code"] ? true : false}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                  fieldValidator(e.target.value, validations["code"], "code");
                }
              }}
              inputProps={{ maxLength: 6 }}
              className="flex-grow-1 w-100 mt-3"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <FormHelperText
              data-testid="confirmSignup-code-error-message"
              error={errorMessage["code"] ? true : false}
              id="standard-weight-helper-text"
              className="mx-0"
            >
              {errorMessage["code"]}
            </FormHelperText>
            <Button
              data-testid="verify-button"
              variant="contained"
              fullWidth
              onClick={() => handleSubmit()}
              className="submit-button"
            >
              Verify
            </Button>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmSignUp;
