import React from "react";
import App from "./app/App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {store} from "./app/redux/store";
import { BrowserRouter } from "react-router-dom";

// SCSS imports
import "bootstrap/dist/css/bootstrap.min.css";
import "placeholder-loading/dist/css/placeholder-loading.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./app/assets/styles/global/global.scss";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
