export const entityTypeMap = {
  1: "Individual",
  2: "Organisation",
  3: "Aircraft",
  4: "Ship",
};

export const entityFilter = (entityFilters, data) => {
  let matchArray = data["matchArray"];

  matchArray = matchArray.filter((element) => {
    let isValid = false;
    const filters = Object.keys(entityFilters);
    for (let i = 0; i < filters.length; i++) {
      if (entityFilters[filters[i]]) {
        isValid =
          isValid ||
          parseInt(element["lookupEntityType"]) === parseInt(filters[i]);
      }
    }
    return isValid;
  });
  return { ...data, matchArray: matchArray };
};
