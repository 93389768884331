import React from "react";
import { useSelector } from "react-redux";
import SearchResultRow  from "./searchResultRow/searchResultRow";
import { Button } from "@mui/material";
import { StringConstants } from "../../../../utility/StringsUtility/Strings";

const SearchResult = () => {
	const searchResultFiltered = useSelector(
		(state) => state.search.searchResultFiltered
	);

	// Sanjay/KK will comeup with new formula to calc this
	// const getLikelihoodOfMatch = () => {
	//   if (searchResultFiltered.match_array?.length === 0) {
	//     return "0%";
	//   }

	//   let nameWeight = 100,
	//     dobWeight = 0,
	//     nationalIdWeight = 0,
	//     addressWeight = 0;

	//   if (
	//     searchResultFiltered.match_array[0]?.query_dob &&
	//     searchResultFiltered.match_array[0]?.query_dob !== ""
	//   ) {
	//     dobWeight = 5;
	//   }
	//   if (
	//     searchResultFiltered.match_array[0]?.query_govt_ids &&
	//     searchResultFiltered.match_array[0]?.query_govt_ids !== ""
	//   ) {
	//     nationalIdWeight = 5;
	//   }
	//   if (
	//     searchResultFiltered.match_array[0]?.query_address &&
	//     searchResultFiltered.match_array[0]?.query_address !== ""
	//   ) {
	//     addressWeight = 5;
	//   }

	//   nameWeight = nameWeight - dobWeight - nationalIdWeight - addressWeight;

	//   const matchProbablity =
	//     searchResultFiltered.match_array[0].score_name * (nameWeight / 100) +
	//     searchResultFiltered.match_array[0].score_dob * (dobWeight / 100) +
	//     searchResultFiltered.match_array[0].score_govtid *
	//       (nationalIdWeight / 100) +
	//     searchResultFiltered.match_array[0].score_address * (addressWeight / 100);
	//   return Math.round(matchProbablity) + "%";
	// };

	const downloadMatchResult = (result) => {
		const a = document.createElement("a");
		const content = JSON.stringify({ matchResults: result });
		const file = new Blob([content], { type: "application/json" });
		a.href = URL.createObjectURL(file);
		a.download = `${result[0].queryName}-Search-Result.json`;
		a.click();
	};

	return (
		<div className="d-flex flex-column w-100">
			<div
				id="searchResultContainer"
				className="d-flex flex-column justify-content-start"
			>
				<div className="search-result-header d-flex justify-content-between pt-1">
					<div
						className="search-result-aggrigation d-flex justify-content-between align-items-center"
						style={{ fontSize: 14 }}
					>
						<b>Result: {searchResultFiltered["matchArray"].length}</b>
						&nbsp; &nbsp; &nbsp; &nbsp;
						<b>List Type: {searchResultFiltered.dataList}</b>
					</div>
					<Button
						data-testid="download-results"
						variant="text"
						onClick={() =>
							downloadMatchResult(searchResultFiltered.matchArray)
						}
						style={{
							color: "#FB665C",
							fontSize: 12,
							textDecorationLine: "underline",
							backgroundColor: "#f8fafb",
						}}
					>
						Download Results
					</Button>
				</div>
				<div className="search-result-rows pt-1">
					{searchResultFiltered.matchArray.map((element) => (
						<SearchResultRow
							key={`ssr-${element.id}`}
							entity={element}
							profileEnabled={searchResultFiltered.dataList?.includes(
								StringConstants.DOWJONES
							)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default SearchResult;
