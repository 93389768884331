import React from "react";
import { useSelector } from "react-redux";
import  NoMatch from "./noMatch/noMatch";
import SearchPlaceholder  from "./searchPlaceholder/searchPlaceholder";
import SearchResult  from "./searchResults/searchResult";

export const SearchOutput = () => {
	const searching = useSelector((state) => state.search.searching);
	const searchResultFiltered = useSelector(
		(state) => state.search.searchResultFiltered
	);

	return (
		<div
			id="searchOutput"
			className="flex-grow-1 d-flex mt-2 w-100 overflow-auto"
		>
			{searching ? (
				<SearchPlaceholder />
			) : (
				<>
					{searchResultFiltered?.matchArray?.length ? (
						<SearchResult />
					) : (
						<NoMatch />
					)}
				</>
			)}
		</div>
	);
};

export default SearchOutput;
