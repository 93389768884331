import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { screeningAPI } from "../api/index.js";

// First, create the thunk
export const searchProfile = createAsyncThunk(
  "profile/searchProfile",
  async (entityId, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setError(false));
    const response = await screeningAPI.profileAPI(entityId);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const profileInitialState = {
  loading: false,
  profileResult: null,
  error: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: { ...profileInitialState },
  reducers: {
    resetProfile: () => profileInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProfileResult: (state, action) => {
      state.matchResult[action.payload].matching = false;
    },
  },
  extraReducers: {
    [searchProfile.fulfilled]: (state, action) => {
      state.profileResult = action.payload;
      state.error = false;
    },
    [searchProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetProfile, setLoading, setProfileResult, setError } =
  profileSlice.actions;

export default profileSlice.reducer;
