/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import "./searchInput.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  matchByString,
  setEntityType,
  setSearchAddress,
  setSearchDob,
  setSearchEntityType,
  setSearchId,
  setSearchString,
} from "../../../../redux/searchRedux";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

const SearchInput = () => {
  const dispatch = useDispatch();
  const searchInputField = useRef(null);
  const searchString = useSelector((state) => state.search.searchString);
  const searchId = useSelector((state) => state.search.searchId);
  const searchDob = useSelector((state) => state.search.searchDob);
  const searchAddress = useSelector((state) => state.search.searchAddress);
  const searchEntityType = useSelector(
    (state) => state.search.searchEntityType
  );
  const [extraOptions, setExtraOptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!extraOptions) {
      if (searchDob?.toLowerCase() === "invalid date") {
        dispatch(setSearchDob(null));
      }
    }
  }, [extraOptions]);

  const entityTypeOptions = {
    U: "Any",
    P: "Individual",
    C: "Entity",
  };

  const search = () => {
    let payload = {
      name: searchString,
      eType: searchEntityType,
    };
    if (searchDob?.toLowerCase() === "invalid date") {
      toast.error("Invalid date entered");
      return;
    }
    if (searchId.trim() !== "") payload["nationalId"] = searchId;
    if (searchDob?.trim() !== "" && searchDob?.toLowerCase() !== "invalid date")
      payload["dob"] = searchDob;
    if (searchAddress.trim() !== "") payload["address"] = searchAddress;

    if (searchId.trim() !== "" || searchString.trim() !== "") {
      if (searchString.trim().length === 0 || searchString.trim().length > 2) {
        navigate("/search");
        dispatch(matchByString(payload));
      } else {
        toast.error("Searched name should be at least 3 characters long");
      }
    } else {
      toast.error("Name (minimum 3 characters) or National Id is required.");
    }

    // Old Logic
    // if (
    //   (searchId.trim() !== "" &&
    //     (searchString.trim().length === 0 || searchString.trim().length > 2)) ||
    //   searchString.trim().length > 2
    // ) {
    //   navigate("/search");
    //   dispatch(matchByString(payload));
    // } else {
    //   toast.error("Searched name should be at least 3 character long");
    // }
  };

  useEffect(() => {
    searchInputField.current.focus();
  }, []);

  return (
    <div id="searchInput" className="d-flex flex-column mt-2 p-4 w-100">
      <div className="w-100 d-flex flex-column flex-md-row px-2 px-md-3 justify-content-center">
        <FormControl
          variant="standard"
          style={{ minWidth: "125px" }}
          className="me-0 me-md-2"
        >
          <InputLabel id="entityTypeLabel">Type</InputLabel>
          <Select
            labelId="entityTypeLabel"
            id="entityType"
            value={searchEntityType}
            label="Type"
            className="w-100"
            onChange={(e) => dispatch(setSearchEntityType(e.target.value))}
          >
            {Object.keys(entityTypeOptions).map((type) => {
              return (
                <MenuItem value={type}>{entityTypeOptions[type]}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className="ms-0 ms-md-1 flex-grow-1 mt-2 mt-md-0"
          label="Name"
          variant="standard"
          placeholder="Search for “Gomez” or “ihad Al Bina” or “Al Baghdadi”  or any other name"
          inputProps={{ "aria-label": "Search" }}
          onChange={(e) => dispatch(setSearchString(e.target.value))}
          name="searchInputs"
          value={searchString}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              search();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle national ID visibility"
                  onClick={() => {
                    dispatch(setSearchString(""));
                  }}
                  edge="end"
                >
                  {searchString !== "" ? <ClearIcon /> : <></>}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={searchInputField}
          autoComplete="off"
        />
        <div className="d-flex mt-4 mt-md-0 align-self-center">
          <Button
            data-testid="search-button"
            variant="contained"
            className="ms-0 ms-md-2 search-button "
            onClick={search}
          >
            Search
          </Button>

          <Button
            data-testid="additional-filters"
            variant="contained"
            className="additional-filters"
            onClick={() => setExtraOptions(!extraOptions)}
          >
            {extraOptions ? (
              <ArrowDropUpOutlinedIcon />
            ) : (
              <ArrowDropDownOutlinedIcon />
            )}
          </Button>
        </div>
      </div>
      {extraOptions ? (
        <div
          className="w-100 d-flex flex-column px-3"
          data-testid="extra-options"
        >
          {/* <hr className="w-100 my-3" /> */}
          <div className="more-search-options mt-3">
            <div data-testid="dob-datepicker">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                sx={{
                  "& .MuiCalendarPicker-root": {
                    height: "300px",
                  },
                }}
              >
                <DatePicker
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="dob"
                  inputFormat="dd/MM/yyyy"
                  label="Date of Birth / Registration"
                  value={searchDob}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                  onChange={(newValue) => {
                    if (newValue) {
                      if (newValue instanceof Date && !isNaN(newValue)) {
                        const dd = String(newValue.getDate()).padStart(2, "0");
                        const mm = String(newValue.getMonth() + 1).padStart(
                          2,
                          "0"
                        ); //January is 0!
                        const yyyy = newValue.getFullYear();
                        const selectedDate = mm + "/" + dd + "/" + yyyy;
                        dispatch(setSearchDob(selectedDate));
                      } else {
                        dispatch(setSearchDob(String(newValue)));
                      }
                    } else {
                      dispatch(setSearchDob(null));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      className="w-100"
                      {...params}
                      sx={{ borderBottom: "1px solid gray" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <TextField
              data-testid="national-id"
              id="national-id"
              label="National ID / Entity ID"
              variant="standard"
              value={searchId}
              onChange={(e) => dispatch(setSearchId(e.target.value))}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle national ID visibility"
                      onClick={() => {
                        dispatch(setSearchId(""));
                      }}
                      edge="end"
                    >
                      {searchId !== "" ? <ClearIcon /> : <></>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField
              data-testid="search-address"
              id="address-id"
              label="Address"
              variant="standard"
              value={searchAddress}
              onChange={(e) => dispatch(setSearchAddress(e.target.value))}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            /> */}
          </div>
        </div>
      ) : (
        <>
          {(searchString !== "" || searchId !== "") &&
            (searchId !== "" || searchDob !== null || searchAddress !== "") && (
              <div className="d-flex mt-2 px-3" data-testid="display-dob">
                {searchDob !== null &&
                  searchDob?.toLowerCase() !== "invalid date" &&
                  searchDob?.trim() !== "" && (
                    <div className="tile p-1 ps-2 me-3">
                      {searchDob.split("/")[1]}/{searchDob.split("/")[0]}/
                      {searchDob.split("/")[2]}{" "}
                      <span
                        className="ms-2"
                        role={"button"}
                        data-testid="display-dob-close-icon"
                        onClick={() => dispatch(setSearchDob(null))}
                      >
                        <Tooltip
                          title={"Remove Date of Birth"}
                          arrow
                          disableInteractive
                        >
                          <CloseIcon color="error" sx={{ fontSize: "1.1em" }} />
                        </Tooltip>
                      </span>
                    </div>
                  )}
                {searchId !== null && searchId?.trim() !== "" && (
                  <div
                    className="tile p-1 ps-2 me-3"
                    data-testid="display-nationality"
                  >
                    {searchId}{" "}
                    <span
                      className="ms-2"
                      role={"button"}
                      data-testid="display-nationality-close-icon"
                      onClick={() => dispatch(setSearchId(""))}
                    >
                      <Tooltip
                        title={"Remove National ID"}
                        arrow
                        disableInteractive
                      >
                        <CloseIcon color="error" sx={{ fontSize: "1.1em" }} />
                      </Tooltip>
                    </span>
                  </div>
                )}
                {searchAddress !== null && searchAddress?.trim() !== "" && (
                  <div
                    className="tile p-1 ps-2 me-3"
                    data-testid="display-address"
                  >
                    {searchAddress}{" "}
                    <span
                      className="ms-2"
                      role={"button"}
                      data-testid="display-address-close-icon"
                      onClick={() => dispatch(setSearchAddress(""))}
                    >
                      <Tooltip
                        title={"Remove Address"}
                        arrow
                        disableInteractive
                      >
                        <CloseIcon color="error" sx={{ fontSize: "1.1em" }} />
                      </Tooltip>
                    </span>
                  </div>
                )}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default SearchInput;
