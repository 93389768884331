import { generateAPIClient } from "./apiClientGenerator";
import { Auth } from "aws-amplify";

export const matchAPI = async (body) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
  }

  return awsApiClient
    .post("/match", {}, body, {
      headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
    })
    .then((response) => {
      if (!response.status === 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const profileAPI = async (entityId) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
  }

  return awsApiClient
    .get(
      "/profile",
      {},
      {},
      {
        queryParams: { entityId: entityId },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};
