import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batchAPI } from "../api/index.js";
import { toast } from "react-toastify";

export const getBatchResult = createAsyncThunk(
  "batchResults/getBatchResult",
  async (batchId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingResult(true));
    const response = await batchAPI.getBatchResult(batchId);
    thunkAPI.dispatch(setLoadingResult(false));
    return response;
  }
);

export const getBatchResultEntity = createAsyncThunk(
  "batchResults/getBatchResultEntity",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoadingEntity(true));
    thunkAPI.dispatch(setBatchResultEntity(null));
    thunkAPI.dispatch(setBatchResultEntityFiltered(null));
    const response = await batchAPI.getBatchResultEntity(
      body.batchId,
      body.entityId
    );
    thunkAPI.dispatch(setLoadingEntity(false));
    return response;
  }
);

const batchResultInitialState = {
  loadingResult: false,
  loadingEntity: true,
  noMatch: false,
  batchResult: null,
  batchResultEntity: null,
  batchResultEntityFiltered: null,
};

export const batchResultSlice = createSlice({
  name: "batchResults",
  initialState: { ...batchResultInitialState },
  reducers: {
    resetBatchResult: () => batchResultInitialState,
    setLoadingResult: (state, action) => {
      state.loadingResult = action.payload;
    },
    setLoadingEntity: (state, action) => {
      state.loadingEntity = action.payload;
    },
    setBatchResult: (state, action) => {
      state.batchResult = action.payload;
    },
    setBatchResultEntity: (state, action) => {
      state.batchResultEntity = action.payload;
    },
    setBatchResultEntityFiltered: (state, action) => {
      state.batchResultEntityFiltered = action.payload;
    },
  },
  extraReducers: {
    [getBatchResult.fulfilled]: (state, action) => {
      const payload = action.payload;
      payload["results"] = payload["results"].filter(
        (result) => result["no_of_candidates"] !== 0 && result["score"] !== 0
      );
      state.batchResult = payload;
    },
    [getBatchResult.rejected]: (state, action) => {
      state.loadingResult = false;
      toast.error(`Error getting batch result, ${action.error.message}`, {
        autoClose: 1000,
      });
    },
    [getBatchResultEntity.fulfilled]: (state, action) => {
      state.batchResultEntity = action.payload;
      state.batchResultEntityFiltered = action.payload;
      let matchFound = false;
      action.payload.results?.forEach((res) => {
        if (res.noOfCandidates !== 0) matchFound = true;
      });
      if (matchFound) {
        state.noMatch = false;
      } else {
        state.noMatch = false;
      }
    },
    [getBatchResultEntity.rejected]: (state, action) => {
      toast.error(`Error getting batch result, ${action.error.message}`, {
        autoClose: 1000,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetBatchResult,
  setLoadingResult,
  setLoadingEntity,
  setBatchResult,
  setBatchResultEntity,
  setBatchResultEntityFiltered,
} = batchResultSlice.actions;

export default batchResultSlice.reducer;
