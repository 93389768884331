import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { metaDataAPI } from "../api/index.js";

export const getSanctionList = createAsyncThunk(
  "meta/getSanctionList",
  async (entityId, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await metaDataAPI.getSanctionListAPI();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const metaDataInitialState = {
  loading: false,
  sanctionList: {},
  error: false,
};

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState: { ...metaDataInitialState },
  reducers: {
    resetMetaData: () => metaDataInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getSanctionList.fulfilled]: (state, action) => {
      state.sanctionList = JSON.parse(action.payload.body);
    },
    [getSanctionList.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { resetMetaData, setLoading } =
metaDataSlice.actions;

export default metaDataSlice.reducer;
