import { Button } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";

import "./footer.scss";

const Footer = () => {
  return (
    <div className="w-100 py-5" id="footer">
      <div className="container d-flex justify-content-between flex-column flex-md-row">
        <div className="d-flex flex-column">
          <h1 className="catchline">
            Accurate risk screening <br />
            and investigations are <br />
            made easier with <br />
            Facctum.
          </h1>

          <Button
            data-testid="contactUs-button"
            onClick={() => {
              let location = window.location.origin.concat("/contact");
              window.open(
                location.replace("facctview.", "").replace("dev-demo.", "")
              );
            }}
            className="contact-button"
          >
            CONTACT US
          </Button>
        </div>
        <div className="d-flex flex-grow-1 justify-content-between justify-content-md-around mt-3 mt-md-0">
          <div className="d-flex flex-column mx-0 mx-md-3 mt-3">
            <span className="content-header">EXPLORE</span>
            <span className="content-segregation mt-2">BY PRODUCT</span>
            <Button
              data-testid="facctview-button"
              onClick={() => {
                let location = window.location.origin;
                location.replace("facctview.", "");
                window.open(location);
              }}
              className="content content-link mt-2"
            >
              <span>FacctView</span>
            </Button>
          </div>
          <div className="d-flex flex-column mx-0 mx-md-3 mt-3">
            <span className="content-header">COMPANY</span>
            <Button
              data-testid="about-button"
              onClick={() => {
                let location = window.location.origin.concat("/about");
                window.open(
                  location.replace("facctview.", "").replace("dev-demo.", "")
                );
              }}
              className="content content-link mt-2"
            >
              About
            </Button>
            <Button
              data-testid="contact-button"
              onClick={() => {
                let location = window.location.origin.concat("/contact");
                window.open(
                  location.replace("facctview.", "").replace("dev-demo.", "")
                );
              }}
              className="content content-link mt-2"
            >
              Contact
            </Button>
            <Button
              data-testid="blog-button"
              onClick={() => {
                let location = window.location.origin.concat("/blog");
                window.open(
                  location.replace("facctview.", "").replace("dev-demo.", "")
                );
              }}
              className="content content-link mt-2"
            >
              Blog
            </Button>
          </div>
        </div>
        <div className="d-flex flex-column mx-0 mx-md-3 mt-3 flex-grow-1 ">
          <span className="content-header">CAREERS</span>
          <Button
            data-testid="privacy-policy-button"
            onClick={() => {
              let location = window.location.origin.concat("/privacy-policy");
              window.open(
                location.replace("facctview.", "").replace("dev-demo.", "")
              );
            }}
            className="content content-link mt-2"
          >
            PRIVACY POLICY
          </Button>
        </div>
        <div className="d-flex flex-column mt-3 ms-1 ms-md-0 align-items-center">
          <IconButton
            className="linkedIn align-self-md-end"
            target="_blank"
            href="https://www.linkedin.com/company/facctum/"
          >
            <LinkedInIcon
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
          <div className="content-segregation mt-2 ms-2 ms-md-0 text-center text-md-end align-self-md-end">
            FACCTUM (C)2022
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
