/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import { setShowLoginModal } from "../../../redux/loginRedux";
import SpinnerBlocker from "../loader/spinnerBlocker/spinnerBlocker";
import "./login.scss";
import LoginModal from "./loginModal/loginModal";
import UserMenu from "./userMenu/userMenu";

const Login = () => {
	const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
	const loading = useSelector((state) => state.login.loading);
	const user = useSelector((state) => state.login.user);
	const dispatch = useDispatch();

	return (
		<>
			{isAuthenticated && user ? (
				<UserMenu user={user} />
			) : (
				<>
					<Button
						data-testid="login-btn"
						variant="outlined"
						className="login-button"
						onClick={() => {
							dispatch(setShowLoginModal(true));
						}}
					>
						LOGIN
					</Button>
				</>
			)}

			<LoginModal />

			{loading && <SpinnerBlocker />}
		</>
	);
};

export default Login;
