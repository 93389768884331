import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { screeningAPI } from "../api/index.js";
import { toast } from "react-toastify";
import { setForceLogin, setForceLoginErrorMessage } from "./loginRedux.js";

// First, create the thunk
export const matchByString = createAsyncThunk(
  "search/matchByString",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setSearching(true));
    thunkAPI.dispatch(setError(false));
    thunkAPI.dispatch(setSearchResult(null));
    thunkAPI.dispatch(setSearchResultFiltered(null));
    let response;

    try {
      response = await screeningAPI.matchAPI(body);
    } catch (err) {
      console.error(err);
      if (err.data.message === "Free Access Quota Depeleted") {
        thunkAPI.dispatch(setForceLogin(true));
        thunkAPI.dispatch(setForceLoginErrorMessage(true))
      } else {
        toast.error(err.data.message);
        thunkAPI.dispatch(setForceLoginErrorMessage(false))
      }
      thunkAPI.dispatch(setError(true));
      throw err;
    }
    thunkAPI.dispatch(setSearching(false));

    return response;
  }
);

const searchInitialState = {
  searching: false,
  searchString: "",
  searchId: "",
  error: false,
  searchDob: null,
  searchAddress: "",
  searchEntityType: "U",
  searchResult: null,
  searchResultFiltered: null,
  limitExceeded: false
};

export const searchSlice = createSlice({
  name: "search",
  initialState: { ...searchInitialState },
  reducers: {
    resetSearch: () => searchInitialState,
    setSearching: (state, action) => {
      state.searching = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    setSearchId: (state, action) => {
      state.searchId = action.payload;
    },
    setSearchDob: (state, action) => {
      state.searchDob = action.payload;
    },
    setSearchAddress: (state, action) => {
      state.searchAddress = action.payload;
    },
    setSearchEntityType: (state, action) => {
      state.searchEntityType = action.payload;
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    setSearchResultFiltered: (state, action) => {
      state.searchResultFiltered = action.payload;
    },
    setError: (state, action) => {
      state.searchResultFiltered = action.payload;
    },
  },
  extraReducers: {
    [matchByString.fulfilled]: (state, action) => {
      state.searchResult = action.payload;
      state.error = false;
      state.searchResultFiltered = action.payload;
    },
    [matchByString.rejected]: (state, action) => {
      state.searching = false;
      state.error = true;
      // alert(JSON.stringify(action));
      toast.error(action.error.message);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetSearch,
  setSearching,
  setSearchString,
  setSearchId,
  setSearchDob,
  setSearchAddress,
  setSearchEntityType,
  setSearchResult,
  setSearchResultFiltered,
  setError,
} = searchSlice.actions;

export default searchSlice.reducer;
