import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export const signUpUser = createAsyncThunk(
  "signUp/signUpUser",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await Auth.signUp({
      password: body.password,
      username: body.email,
      attributes: {
        name: body.name,
        email: body.email,
        // phone_number: body.mobileNumber,
        "custom:Contact": body.mobileNumber,
        "custom:Company": body.company,
        "custom:Industry": body.industry,
        "custom:Company_Size": body.companySize,
        "custom:Designation": body.designation,
        "custom:Country": body.country,
      },
    });
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const confirmSignUp = createAsyncThunk(
  "signUp/confirmSignUp",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const username = body.email;
    const response = await Auth.confirmSignUp(username, body.code);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const initialSignUpState = {
  loading: false,
  registered: false,
  openVerifySignUpModal: false,
  email: null,
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState: { ...initialSignUpState },
  reducers: {
    resetSignUp: () => initialSignUpState,
    // These are just examples, will remove as developers are aware of how redux works
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setOpenVerifySignUpModal: (state, action) => {
      state.openVerifySignUpModal = action.payload;
    },
    setEmailId: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: {
    [signUpUser.fulfilled]: (state, action) => {
      toast.success(`Sign-up Successful. Please check you mail`);
      state.loading = false;
      state.registered = true;
      state.openVerifySignUpModal = true;
      state.email = action.payload.user.username;
    },
    [signUpUser.rejected]: (state, action) => {
      toast.error(`${action.error.message}`);
      state.loading = false;
      state.error = action.payload;
    },
    [confirmSignUp.fulfilled]: (state, action) => {
      toast.success(`Account Verified!`);
      state.loading = false;
      state.openVerifySignUpModal = false;
    },
    [confirmSignUp.rejected]: (state, action) => {
      if(action.error.message === "User cannot be confirmed. Current status is CONFIRMED"){
        toast.error("Account already activated for the mentioned Email ID - Please use 'Forgot Password' to change your password");
      }
      else{
        toast.error(`${action.error.message}`);
      }
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, resetSignUp, setOpenVerifySignUpModal, setEmailId } =
  signUpSlice.actions;

export default signUpSlice.reducer;
