/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForceLogin, setShowLoginModal } from "../../../../redux/loginRedux";
import { useNavigate } from "react-router-dom";

import "./loginPopup.scss";

const LoginPopup = () => {
	const forceLogin = useSelector((state) => state.login.forceLogin);
	const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
	const limitExceeded = useSelector((state) => state.search?.searchResult?.apiLimit);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "26%",
		padding: "14%",
		bgcolor: "#FFFFFF",
		borderRadius: "16px",
		boxShadow: 24,
		p: 4,
		outline: "none",
	};

	useEffect(() =>{
		if(limitExceeded){
			setTimeout(() =>{
				dispatch(setForceLogin(true));
			}, 800)
		}
	},[limitExceeded])

	useEffect(() => {
		if (isAuthenticated && forceLogin) {
			navigate("/contactus");
			dispatch(setForceLogin(false));
		}
	}, [isAuthenticated, forceLogin]);

	return (
		<>
			<Modal
				id="loginPopup"
				open={forceLogin}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{
					backdropFilter: "blur(5px)",
				}}
			>
				<Box sx={style}>
					<div className="px-4 py-5 login-container">
						<p className="loginPopup-title">
							Thank you for your interest
						</p>
						<p className="loginPopup-subtitle">
							Please register to continue using our solution for free
						</p>

						<div className="w-100">
							<Button
								data-testid="signup-button"
								variant="contained"
								onClick={() => {
									dispatch(setForceLogin(false));
									navigate("/signUp");
								}}
								className="mt-3 w-100"
								color="error"
								style={{ backgroundColor: "#fb665c" }}
							>
								REGISTER
							</Button>
						</div>
						<div className="mt-3 text-center"> 
							<p
								style={{ fontSize: "0.9em", marginBottom: 0 }}
							>
								Already registered?{" "}
								<b
									data-testid="login-button"
									role={"button"}
									className="ms-1"
									style={{ color: "#238ce9" }}
									onClick={() => {
										dispatch(setForceLogin(false));
										dispatch(setShowLoginModal(true));
									}}
								>
									Login
								</b>
							</p>
						</div>
						<div className="text-center">
							<a
								href="https://facctum.com/facctview/"
								target="_blank"
								className="gotoproduct-link"
								>
									Know more
								</a>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default LoginPopup;
