import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { setSearchAddress, setSearchDob, setSearchId, setSearchString } from "./searchRedux";
import { capitalizeFirstLetter } from "../components/utility/StringsUtility/Strings";

export const checkSessionForUser = createAsyncThunk(
  "login/checkSessionForUser",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));

    // For guest user
    Auth.currentCredentials();
    Auth.currentSession();

    // const currentSession = await Auth.currentSession();
    // cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    //   console.log("session", err, session);
    //   const { idToken, refreshToken, accessToken } = session;
    //   // do whatever you want to do now :)
    // });

    const cognitoUser = await Auth.currentAuthenticatedUser();
    thunkAPI.dispatch(setLoading(false));
    return cognitoUser;
  }
);

export const signIn = createAsyncThunk(
  "login/signIn",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await Auth.signIn({
      username: body.username,
      password: body.password,
    });
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const signOut = createAsyncThunk(
  "login/signOut",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await Auth.signOut();
    thunkAPI.dispatch(setLoading(false));
    thunkAPI.dispatch(setSearchString(""));
    thunkAPI.dispatch(setSearchId(""));
    thunkAPI.dispatch(setSearchDob(""));
    thunkAPI.dispatch(setSearchAddress(""));
    return response;
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    sessionLoginAttempt: false,
    loading: false,
    error: null,
    isAuthenticated: false,
    user: null,
    showLoginModal: false,
    forceLogin: false,
    showForceLoginErrorMessage: false
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    // These are just examples, will remove as developers are aware of how redux works
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setForceLogin: (state, action) => {
      state.forceLogin = action.payload;
    },
    setForceLoginErrorMessage: (state, action) => {
      state.showForceLoginErrorMessage = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
  },
  extraReducers: {
    [signIn.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      toast.success(
        `Sign-In Successful, Welcome ${capitalizeFirstLetter(action.payload?.attributes?.name)}`
      );
      state.user = action.payload;
    },
    [signIn.rejected]: (state, action) => {
      toast.error(`${action.error.message}`);
      state.loading = false;
      state.error = action.payload;
    },
    [checkSessionForUser.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.sessionLoginAttempt = true;
      state.loading = false;
      state.user = action.payload;
      // toast.success(`Welcome ${action.payload.attributes.name}`, {
      //   autoClose: 1000,
      // });
    },
    [checkSessionForUser.rejected]: (state, action) => {
      state.loading = false;
      state.sessionLoginAttempt = true;
      state.error = action.payload;
    },
    [signOut.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      toast.success(`Sign-out Successful`, {
        autoClose: 1000,
      });
    },
    [signOut.rejected]: (state, action) => {
      toast.success(`Sign-out Successful`, {
        autoClose: 1000,
      });
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAuthenticated,
  setUser,
  setForceLogin,
  setShowLoginModal,
  setForceLoginErrorMessage
} = loginSlice.actions;

export default loginSlice.reducer;
