import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { batchAPI } from "../api/index.js";
import { toast } from "react-toastify";

export const generatePreSignedUrl = createAsyncThunk(
  "createBatch/generatePreSignedUrl",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await batchAPI.generatePreSignedURL(body);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const uploadBatchFile = createAsyncThunk(
  "createBatch/uploadBatchFile",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await batchAPI.uploadBatchFile(body);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const createBatchInitialState = {
  loading: false,
  uploaded: false,
  preSignedURL: null,
};

export const createBatchSlice = createSlice({
  name: "createBatch",
  initialState: { ...createBatchInitialState },
  reducers: {
    resetCreateBatch: () => createBatchInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUploaded: (state, action) => {
      state.uploaded = action.payload;
    },
    setPreSignedURL: (state, action) => {
      state.preSignedURL = action.payload;
    },
  },
  extraReducers: {
    [generatePreSignedUrl.fulfilled]: (state, action) => {
      state.preSignedURL = JSON.parse(action.payload.body);
    },
    [generatePreSignedUrl.rejected]: (state, action) => {
      toast.error(`Error Generating Pre Signed URL, ${action.error.message}`, {
        autoClose: 1000,
      });
    },
    [uploadBatchFile.fulfilled]: (state, action) => {
      state.uploaded = true;
      toast.success(`Batch File Uploaded and Batch Job Created`, {
        autoClose: 1000,
      });
    },
    [uploadBatchFile.rejected]: (state, action) => {
      toast.error(`Error Uploading File, ${action.payload}`, {
        autoClose: 1000,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetCreateBatch, setLoading, setUploaded, setPreSignedURL } =
  createBatchSlice.actions;

export default createBatchSlice.reducer;
