/* eslint-disable react-hooks/exhaustive-deps */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import aircraft from "../../../../../../assets/images/icons/Aircraft.svg";
import org from "../../../../../../assets/images/icons/Organization.svg";
import person from "../../../../../../assets/images/icons/Person.svg";
import ship from "../../../../../../assets/images/icons/Ship.svg";
import DescriptionOne from "../../../../../../resource/descriptionList";
import OccupationList from "../../../../../../resource/occupationList";
import SanctionList from "../../../../../../resource/sactionList";
import { getSanctionFilter } from "../../../../../utility/sanctionUtility/sanctionUtility";
import OverflowTip from "../../../../overFlowTip/OverFlowTip";
import "./searchResultRow.scss";

const SearchResultRow = ({ entity, profileEnabled }) => {
  const [expandSummary, setExpandSummary] = useState(false);

  const [searchData, setSearchData] = useState({});
  const [descriptionList, setDescriptionList] = useState([]);
  const [sanctionList, setSanctionList] = useState([]);
  const sanctionLists = useSelector((state) => state.metaData.sanctionList);

  const getEntityTypeIcon = (entityType) => {
    switch (parseInt(entityType)) {
      case 1:
        return <img src={person} alt="personLogo" data-testid="personLogo" />;
      case 2:
        return (
          <img
            src={org}
            alt="organizationLogo"
            data-testid="organizationLogo"
          />
        );
      case 3:
        return (
          <img src={aircraft} alt="aircraftLogo" data-testid="aircraftLogo" />
        );
      case 4:
        return <img src={ship} alt="shipLogo" data-testid="shipLogo" />;
      default:
        return <QuestionMarkIcon data-testid="QuestionMarkIcon" />;
    }
  };

  useEffect(() => {
    let sampleSearchData = {};
    sampleSearchData["searchString"] = entity["queryName"];
    sampleSearchData["searchDob"] = entity["queryDob"];
    sampleSearchData["searchId"] = entity["queryGovtIds"];
    sampleSearchData["searchAddress"] = entity["queryAddress"];
    setSearchData(sampleSearchData);
    setDescriptionList(getDiscriptionList());
    setSanctionList(getSanctionList());
  }, []);

  const getDiscriptionList = () => {
    const newDescriptionsList = [];
    if (entity["descriptionIds"]) {
      const descriptions = JSON.parse(entity["descriptionIds"]);
      descriptions.forEach((descriptionIds) => {
        let description = {};
        let descLength = Object.keys(descriptionIds).length;
        if (descLength == 2) {
          description = {
            name: descriptionIds.Description1name,
          };
        } else if (descLength > 2) {
          description = {
            name: descriptionIds.Description2name,
          };
        }
        if (descLength == 6) {
          description = {
            ...description,
            childDescription: { name: descriptionIds.Description3name },
          };
        }
        if (description) newDescriptionsList.push(description);
      });
    }
    return newDescriptionsList;
  };

  const getSanctionList = () => {
    const newSanctionList = [];
    if (entity["sanctionsReferenceIds"]) {
      const sanctionsIds = JSON.parse(entity["sanctionsReferenceIds"]);
      sanctionsIds.forEach((sanctionId) => {
        if (sanctionLists[sanctionId]) {
          newSanctionList.push(sanctionLists[sanctionId]);
        }
      });
    }
    return newSanctionList;
  };

  const getStrength = (type) => {
    const matchList = {};

    entity["matchCode"].split("|").forEach((match) => {
      if (match.trim() !== "") {
        matchList[match.split(":")[0]?.trim()] = match.split(":")[1]?.trim();
      }
    });

    return matchList.hasOwnProperty(type)
      ? matchList[type].toLowerCase()
      : "fail";
  };

  return (
    <div
      id={"searchRow-" + entity.id}
      className="search-row  d-flex flex-column p-2 mb-2"
    >
      <div
        className="search-row-summary w-100 d-flex align-items-center col-12"
        role="button"
        data-testid="search-row-summary"
        onClick={() => setExpandSummary(!expandSummary)}
      >
        <div className="text-center pe-3 border-right-gray">
          <b className="search-row-score">{entity.score}</b>
          <div className="search-row-entity-icon pt-2">
            {getEntityTypeIcon(entity["lookupEntityType"])}
          </div>
        </div>
        <div className="search-row-entity-name d-flex flex-column ps-3 px-2 flex-grow-1 col-10 col-md-7">
          <h6 className="mb-0 body-bold elipsis">
            <OverflowTip
              className={"mb-0 body-bold"}
              value={entity.lookupPrimaryName}
            />
          </h6>
          <OverflowTip
            className={"body-small-normal"}
            value={`Matched Name: ${entity.matchedName} (${entity.nameType})`}
          />
          <span className="body-small-normal mt-1">
            {searchData["searchString"] !== "" && (
              <span className="me-3">
                Name:{" "}
                <b
                  className={`${
                    entity["entityType"] === "Individual"
                      ? getStrength("Ind_name")
                      : getStrength("Org_name")
                  }`}
                >
                  {entity["entityType"] === "Individual"
                    ? getStrength("Ind_name").toUpperCase()
                    : getStrength("Org_name").toUpperCase()}
                </b>
              </span>
            )}
            {searchData["searchDob"] !== "" && (
              <span className="me-3">
                {parseInt(entity["lookupEntityType"]) !== 1 ? (
                  <>Date of Registration: </>
                ) : (
                  <>Date of Birth: </>
                )}
                <b className={`${getStrength("DOB")}`}>
                  {getStrength("DOB").toLocaleUpperCase()}
                </b>
              </span>
            )}

            {searchData["searchId"] !== "" && (
              <span className="me-3">
                National ID:{" "}
                <b className={`${getStrength("ID")}`}>
                  {getStrength("ID").toLocaleUpperCase()}
                </b>
              </span>
            )}

            {searchData["searchAddress"] !== "" && (
              <span className="me-3">
                Address:{" "}
                <b className={`${getStrength("ADDRESS")}`}>
                  {getStrength("ADDRESS").toLocaleUpperCase()}
                </b>
              </span>
            )}
          </span>
        </div>

        <div className="search-row-list-type d-flex align-items-center justify-content-end px-2 col-6 col-md-2">
          {/* work on this - done - not sure what logic you wrote @Samarth */}
          {Object.keys(getSanctionFilter(entity["lookupEntityFilters"])).map(
            (type, index) =>
              index < 2 && (
                <div
                  className="m-2  d-flex align-items-center"
                  key={`et-${type}`}
                  style={{ fontSize: "12px" }}
                >
                  <span
                    className={`entity-list-type-box ${type} ${type}-shadow`}
                  />
                  <span className={`${type} entity-list-type`}>{type}</span>
                </div>
              )
          )}
          {Object.keys(getSanctionFilter(entity["lookupEntityFilters"]))
            .length > 2 && (
            <span className="listTypeMore small-label-bold">
              +
              {Object.keys(getSanctionFilter(entity["lookupEntityFilters"]))
                .length - 2}
            </span>
          )}
        </div>

        <div className="search-row-list-dob text-center col-5 col-md-1 ">
          <span className="label-2-caps">
            {entity.dob && entity.dob.split("|")[0]}
            {entity.dob.split("|").length > 1 && "..."}
          </span>
        </div>

        <div className="search-row-entity-expand d-flex justify-content-end px-2">
          {expandSummary ? (
            <KeyboardArrowUpIcon data-testid="KeyboardArrowUpIcon" />
          ) : (
            <KeyboardArrowDownIcon data-testid="KeyboardArrowDownIcon" />
          )}
        </div>
      </div>

      {expandSummary && (
        <div
          className="search-row-expanded w-100 d-flex flex-column"
          data-testid="search-row-expanded"
        >
          <hr className="w-100" />
          <div className="w-100 d-flex flex-column px-3">
            <div className="search-row-expanded-name-row w-100 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div
                className="d-flex search-row-expanded-name"
                style={{ wordBreak: "break-all" }}
              >
                <span style={{ wordBreak: "keep-all" }}>Full Name:</span> &nbsp;
                {entity.lookupPrimaryName}
              </div>
              {profileEnabled && (
                <Button
                  component={Link}
                  variant="outlined"
                  color="error"
                  to={`/profile?entityId=${entity["id"]}`}
                  target="_blank"
                  className="mt-2 mt-md-0"
                  style={{
                    minWidth: "100px",
                    color: "#FB665C",
                    borderColor: "#FB665C",
                    fontSize: 12,
                    letterSpacing: "0.06rem",
                  }}
                >
                  Profile <ArrowForwardIcon />
                </Button>
              )}
            </div>

            <div className="search-row-expanded-name-row w-100 d-flex flex-column justify-content-start align-items-start mt-2">
              <div className="d-flex search-row-expanded-name align-items-center ">
                <span style={{ wordBreak: "keep-all" }}>Lists</span> &nbsp;
                <div className="d-flex  flex-wrap  align-items-center ">
                  {Object.keys(
                    getSanctionFilter(entity["lookupEntityFilters"])
                  ).map((type) => (
                    <div
                      className=" d-flex align-items-center justify-content-start ps-3"
                      key={`et-${type}`}
                      style={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      <span
                        className={`entity-list-type-box ${type} ${type}-shadow`}
                      />
                      <span className={`${type} entity-list-type ps-1`}>
                        {type}
                        {type === "PEP" && (
                          <span>
                            {"("}
                            {entity["occupationCategoryName"]}
                            {")"}
                          </span>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {descriptionList?.length ? (
                <div
                  className="search-row-expanded-name-row w-100 d-flex justify-content-start align-items-start mt-2 mb-3"
                  data-testid="description-name"
                >
                  <div
                    className="d-flex search-row-expanded-name align-items-center"
                    style={{ wordBreak: "break-all" }}
                  >
                    <div className="d-flex flex-wrap align-items-start align-items-md-center search-desc-item justify-content-start flex-column flex-md-row">
                      {descriptionList.map(
                        (description, index) =>
                          description?.name && (
                            <div
                              key={description.id}
                              className="d-flex ms-0 ms-md-2 mb-2 align-items-center px-1"
                            >
                              <div
                                className="px-0 px-md-2 py-1 d-flex align-items-center justify-content-start"
                                key={`et-${index}`}
                                style={{ fontSize: "12px" }}
                              >
                                {description.name}
                              </div>{" "}
                              {description.childDescription && (
                                <React.Fragment>
                                  <span className={"mx-1"}>-</span>
                                  <div
                                    className="px-0 px-md-2 py-1 d-flex align-items-center justify-content-start"
                                    key={`et-${index}`}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {description.childDescription.name}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {entity["dob"] !== "" && (
                <div className="search-row-expanded-name-row w-100 d-flex justify-content-start align-items-start mt-2">
                  <div
                    className="d-flex search-row-expanded-name align-items-center"
                    style={{ wordBreak: "break-all" }}
                  >
                    <span style={{ wordBreak: "keep-all" }}>
                      {parseInt(entity["lookupEntityType"]) !== 1 ? (
                        <>Date of Registration: </>
                      ) : (
                        <>Date of Birth: </>
                      )}
                    </span>{" "}
                    &nbsp;
                    <div className="d-flex flex-wrap align-items-center search-dob-item">
                      {entity["dob"].split("|").map((idx, dob) => (
                        <div
                          className="px-2 py-1 d-flex align-items-center justify-content-start ps-2 mb-1 mb-md-0"
                          key={`et-${idx}`}
                          style={{ fontSize: "12px" }}
                        >
                          {idx}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {sanctionList?.length ? (
              <div
                className="w-100 d-flex justify-content-start align-items-start mt-4"
                style={{ flexDirection: "column" }}
              >
                <span style={{ fontSize: 14, fontWeight: "bold" }}>
                  Sanction Details:
                </span>
                <div>
                  <ul>
                    {sanctionList.map(
                      (sanction) =>
                        sanction?.name && (
                          <li key={sanction.id}>
                            <span style={{ fontSize: 14 }}>
                              {sanction.name}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="w-100 d-flex mt-2 pb-2">
              <div className="search-row-expanded-name-card w-100 d-flex flex-column justify-content-between align-items-center">
                {searchData["searchString"] !== "" &&
                  entity["searchString"] !== "" && (
                    <Paper
                      elevation={2}
                      className="w-100 p-3 d-flex flex-column details-box"
                    >
                      <h6 className="name" style={{ wordBreak: "break-all" }}>
                        Name
                      </h6>
                      <div
                        className="query "
                        style={{ wordBreak: "break-all", fontSize: 14 }}
                      >
                        Original Query:{" "}
                        <b>
                          {searchData["searchString"]}{" "}
                          {getStrength("Ind_name") === "exact" ? (
                            "="
                          ) : (
                            <>&#8776;</>
                          )}{" "}
                          <i>{entity["matchedName"]}</i>
                        </b>
                      </div>
                      <div
                        className="query mt-2"
                        style={{ wordBreak: "break-all", fontSize: 14 }}
                      >
                        Match Type : <i>{entity["nameType"]}</i>
                      </div>
                      <div className="query d-flex justify-content-between align-items-center mt-3">
                        <span>
                          <span style={{ fontSize: 14 }}>Match Strength: </span>
                          <span className="match-strength-value">
                            {entity["entityType"] !== "Individual"
                              ? getStrength("Org_name").toUpperCase()
                              : getStrength("Ind_name").toUpperCase()}
                          </span>
                        </span>
                        <span className="d-flex align-items-center">
                          <span>
                            Match Score: <b>{entity["scoreName"]}</b>
                          </span>
                        </span>
                      </div>
                    </Paper>
                  )}
                {searchData["searchDob"] !== "" && entity["scoreDob"] !== "" && (
                  <Paper
                    elevation={2}
                    className="w-100 p-3 d-flex flex-column mt-3"
                  >
                    <h6 className="name" style={{ wordBreak: "break-all" }}>
                      Date of birth
                    </h6>
                    <div
                      className="query mt-2"
                      style={{ wordBreak: "break-all", fontSize: 14 }}
                    >
                      Original Query:{" "}
                      <b>
                        {searchData["searchDob"]}{" "}
                        {getStrength("DOB") === "exact" ? "=" : <>&#8776;</>}{" "}
                        <i>{entity["dob"]}</i>
                      </b>
                    </div>
                    <div className="query d-flex justify-content-between align-items-center mt-3">
                      <span>
                        Match Strength:{" "}
                        <span className="match-strength-value">
                          {getStrength("DOB").toUpperCase()}
                        </span>
                      </span>
                      <span className="d-flex align-items-center">
                        <span>
                          Match Score: <b>{entity["scoreDob"]}</b>
                        </span>
                      </span>
                    </div>
                  </Paper>
                )}
                {searchData["searchId"] !== "" && entity["scoreGovtid"] !== "" && (
                  <Paper
                    elevation={2}
                    className="w-100 p-3 d-flex flex-column mt-3"
                  >
                    <h6 className="name" style={{ wordBreak: "break-all" }}>
                      National ID
                    </h6>
                    <div className="query mt-2">
                      Original Query:{" "}
                      <b>
                        {searchData["searchId"]}{" "}
                        {getStrength("ID") === "exact" ? "=" : <>&#8776;</>}{" "}
                        <i>{entity["govtIds"]}</i>
                      </b>
                    </div>
                    <div className="query d-flex justify-content-between align-items-center mt-3">
                      <span>
                        Match Strength:{" "}
                        <span className="match-strength-value">
                          {getStrength("ID").toUpperCase()}
                        </span>
                      </span>
                      <span className="d-flex align-items-center">
                        <span>
                          Match Score: <b>{entity["scoreGovtid"]}</b>
                        </span>
                      </span>
                    </div>
                  </Paper>
                )}
                {searchData["searchAddress"] !== "" &&
                  entity["scoreAddress"] !== "" && (
                    <Paper
                      elevation={2}
                      className="w-100 p-3 d-flex flex-column mt-3"
                    >
                      <h6 className="name" style={{ wordBreak: "break-all" }}>
                        Address
                      </h6>
                      <div className="query mt-2">
                        Original Query:{" "}
                        <b>
                          {searchData["searchAddress"]}{" "}
                          {getStrength("ADDRESS") === "exact" ? (
                            "="
                          ) : (
                            <>&#8776;</>
                          )}
                          <i>{entity["addressLine"]}</i>
                        </b>
                      </div>
                      <div className="query d-flex justify-content-between align-items-center mt-3">
                        <span>
                          Match Strength:{" "}
                          <span className="match-strength-value">
                            {getStrength("ADDRESS").toUpperCase()}
                          </span>
                        </span>
                        <span className="d-flex align-items-center">
                          <span>
                            Match Score: <b>{entity["scoreAddress"]}</b>
                          </span>
                        </span>
                      </div>
                    </Paper>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResultRow;
