import { generateAPIClient } from "./apiClientGenerator";
import { Auth } from "aws-amplify";

export const generatePreSignedURL = async (body) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .post("/batchpresignedurl", {}, body, {
      headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
    })
    .then((response) => {
      if (!response.status === 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const uploadBatchFile = (body) => {
  return fetch(body["url"], {
    method: "PUT",
    headers: { "Content-Type": "text/csv" },
    body: body["file"],
  })
};

export const getAllBatch = async (userName) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .get(
      "/batchdetails",
      {},
      {},
      {
        queryParams: { userName: userName },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const getBatchSummary = async (batchId) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .get(
      "/batchsummary",
      {},
      {},
      {
        queryParams: { batchId: batchId },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const getBatchResult = async (batchId) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .get(
      "/batch",
      {},
      {},
      {
        queryParams: { batchId: batchId },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const getBatchResultEntity = async (batchId, queryId) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .get(
      "/batchmatches",
      {},
      {},
      {
        queryParams: { batchId: batchId, queryId: queryId },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};

export const getBatchResultURL = async (batchId) => {
  const awsApiClient = await generateAPIClient();

  let cognitoUser = null;

  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.error(err);
    throw Error("Unauthorized access!!!");
  }

  return awsApiClient
    .get(
      "/batchfilelink",
      {},
      {},
      {
        queryParams: { batchId: batchId },
        headers: { idtoken: cognitoUser?.signInUserSession?.idToken?.jwtToken },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Internal Error, check with website owner");
      }
      return response.data;
    });
};
