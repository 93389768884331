/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import DoneIcon from "@mui/icons-material/Done";
import "./filter.scss";
import { setSearchResultFiltered } from "../../../../redux/searchRedux";
import {
  filterBySanctions,
  generateSanctionFilters,
  sanctionsListFullForm,
} from "../../../utility/sanctionUtility/sanctionUtility";
import {
  entityFilter,
  entityTypeMap,
} from "../../../utility/entityUtility/entityUtility";

const Filter = () => {
  const dispatch = useDispatch();

  const masterSearchResult = useSelector((state) => state.search.searchResult);

  const [sanctionFilters, setSanctionFilters] = useState({});
  const [entityFilters, setEntityFilters] = useState({});

  useEffect(() => {
    generateFilters();
  }, [masterSearchResult]);

  const generateFilters = () => {
    let tempEntityList = {};
    if (masterSearchResult && masterSearchResult["matchArray"]) {
      setSanctionFilters(
        generateSanctionFilters(masterSearchResult["matchArray"])
      );

      masterSearchResult["matchArray"].forEach((element) => {
        tempEntityList[element["lookupEntityType"]] = true;
      });
      setEntityFilters(tempEntityList);
    } else {
      setSanctionFilters({});
      setEntityFilters({});
    }
  };

  useEffect(() => {
    if (
      masterSearchResult &&
      masterSearchResult["matchArray"] &&
      masterSearchResult["matchArray"].length > 0
    ) {
      const sanctionFilteredData = filterBySanctions(
        sanctionFilters,
        masterSearchResult
      );
      const entityFilteredData = entityFilter(
        entityFilters,
        sanctionFilteredData
      );
      dispatch(setSearchResultFiltered(entityFilteredData));
    }
  }, [sanctionFilters, entityFilters]);

  return (
    <div
      id="searchFilter"
      className={`pt-4 d-flex flex-column align-items-start px-5 text-start searched-background justify-content-start`}
    >
      <span className="catchline">
        New Screening Technology <br />
        For Financial Crime <br />
        Risk Management
      </span>

      {Object.keys(sanctionFilters).length > 0 && (
        <a
          role="button"
          onClick={generateFilters}
          className="mt-5 reset-filters"
          style={{ textDecoration: "none" }}
        >
          RESET FILTERS
        </a>
      )}

      {Object.keys(sanctionFilters).length > 0 && (
        <div className="mt-2  pb-3 list-type-filters">
          <h3 className="h3-semibold mb-1">Searching For:</h3>
          <div id={"sanctionFilters"}>
            {Object.keys(sanctionFilters).map((key, index) => (
              <Tooltip
                title={sanctionsListFullForm[key]}
                arrow
                disableInteractive
                key={`tool-${index}`}
              >
                <Button
                  data-testid={`listFilterBtn-${index}`}
                  variant={sanctionFilters[key] ? "contained" : "outlined"}
                  key={key}
                  className={`me-2 mb-2 py-1 px-2 ${
                    sanctionFilters[key]
                      ? "list-search-filter-on"
                      : "list-search-filter-off"
                  }`}
                  onClick={() => {
                    setSanctionFilters({
                      ...sanctionFilters,
                      [key]: !sanctionFilters[key],
                    });
                  }}
                >
                  {sanctionFilters[key] && (
                    <>
                      <DoneIcon style={{ padding: 0 }} />
                      &nbsp;
                    </>
                  )}{" "}
                  {key}
                </Button>
              </Tooltip>
            ))}
          </div>
        </div>
      )}

      {Object.keys(entityFilters).length > 0 && (
        <div className="mt-4">
          <h3 className="h3-semibold">Type:</h3>
          <div id={"entityFilters"} className=" d-flex flex-column">
            {Object.keys(entityFilters).map((key, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid={`entityFilterBtn-${index}`}
                    checked={entityFilters[key] === true}
                    sx={{
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#fff",
                      },
                    }}
                    onChange={() => {
                      setEntityFilters({
                        ...entityFilters,
                        [key]: !entityFilters[key],
                      });
                    }}
                  />
                }
                label={entityTypeMap[key]}
                variant={entityFilters[key] ? "contained" : "outlined"}
                color="error"
                key={key}
                className="me-2 mb-1"
              >
                {entityTypeMap[key]}
              </FormControlLabel>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
