import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import facctviewWhiteLogo from "../../../assets/images/logos/facctviewWhiteLogo.svg";
import { resetSearch } from "../../../redux/searchRedux";
import Header from "../header/header";
import SearchInput from "../search/searchInput/searchInput";
import TermsAndConditionPopup from "./termsAndConditionPopup/termsAndConditionPopup.js";
import PrivacyPolicyPopup from "./privacyPolicy/privacyPolicy";
import "./home.scss";

const Home = () => {
  const dispatch = useDispatch();

  const [openTermsAndConditionModal, setOpenTermsAndConditionModal] =
    useState(false);
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

  useEffect(() => {
    dispatch(resetSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div
        id="home"
        className="page fluid-container container-md d-flex flex-column  justify-content-start  mb-5 pt-5"
        color=""
      >
        <Container>
          <div className="d-flex flex-column justify-content-center mt-5 pt-5">
            <div>
              <img
                src={facctviewWhiteLogo}
                alt="FacctumLogo"
                className="facctview-home-logo"
              />
            </div>
            <div className="title mt-3">
              New screening technology <br />
              for financial crime risk management
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center mt-2">
            <div className="w-100">
              <SearchInput />
            </div>
          </div>
        </Container>
        <div className="d-flex justify-content-center mt-2 help-text">
          <span className="text-center">
          By using our solution you automatically accept our{" "}
          <span
            data-testid="terms-and-condition"
            className="ms-1"
            style={{ color: "#fff", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setOpenTermsAndConditionModal(true)}
          >
            Terms and Conditions{" "}
          </span>{" "}
          &nbsp; and
          <span
            data-testid="privacy-policy"
            className="ms-1"
            style={{ color: "#fff", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setOpenPrivacyPolicyModal(true)}
          >
            Privacy Policy
          </span>
          </span>
        </div>
        <div id="note" className="d-flex justify-content-center">
          <div className="note-box p-3">
            <p className="body-small-normal">
              Access to FacctView is provided for purposes of product
              evaluation. OFAC SDN data is sourced directly from &nbsp;
              <a
                className="ofac-link"
                target="_blank"
                href="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information"
                rel="noreferrer"
              >
                OFAC Specially Designated Nationals Data (treasury.gov).
              </a>
              &nbsp; Facctum does not enrich this data. Information will be
              updated on an occasional basis. The criteria and results of
              searches are recorded and stored by Facctum. Use of this service
              does not constitute or infer Facctum providing any form of legal
              or compliance guidance. Please contact us at our email address: {" "}     
              <a
                className="ofac-link"
                href="mailto:info@facctum.com"
                rel="noreferrer"
                target="_top"
              >
                info@facctum.com
              </a>
              {" "} if you have any
              questions.
            </p>
          </div>
        </div>
      </div>
      <TermsAndConditionPopup
        modalOpen={openTermsAndConditionModal}
        handleClose={() => setOpenTermsAndConditionModal(false)}
      />
      <PrivacyPolicyPopup
        modalOpen={openPrivacyPolicyModal}
        handleClose={() => setOpenPrivacyPolicyModal(false)}
      />
    </>
  );
};

export default Home;
