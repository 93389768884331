/* eslint-disable react-hooks/exhaustive-deps */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowLoginModal, signIn } from "../../../../redux/loginRedux";
import { setOpenVerifySignUpModal } from "../../../../redux/signupRedux";
import {
  NotNullValidation,
  ValidEmail,
  BlacklistEmail
} from "../../../utility/validation/validations";
import { validate } from "../../../utility/validation/validator";
import ConfirmSignUp from "../../confirmSignUp/confirmSignUp";
import ForgotPassword from "../../forgotPassword/forgotPassword";
import ResendConfirmation from "../../resendConfirmation/resendConfirmation";
import { Auth } from "aws-amplify";

import "./loginModal.scss";

const LoginModal = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLoginModal = useSelector((state) => state.login.showLoginModal);
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const passwordChangeSuccess = useSelector(
    (state) => state.forgotPassword.passwordChanged
  );
  const [resendConfirmationModal, setResendConfirmationModal] = useState(false);
  const mailSent = useSelector((state) => state.resendConfirmation.mailSent);
  const [showPassword, setShowPassword] = useState(false);
  const openVerifySignupModal = useSelector(
    (state) => state.signUp.openVerifySignUpModal
  );
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    password: "",
  });
  const validations = {
    email: [NotNullValidation, ValidEmail, BlacklistEmail],
    password: [NotNullValidation],
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
		if (username !== "") {
		  const { error, errorText } = validate(username, validations["email"]);
		  if (error) {
			setErrorMessage({
			  ...errorMessage,
			  email: errorText,
			});
		  }
		}
	  }, []);

  useEffect(() => {
    if (isAuthenticated && showLoginModal) {
      navigate("/");
      dispatch(setShowLoginModal(false));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (mailSent) onCloseResendConfirmation();
  }, [mailSent]);

  useEffect(() => {
    if (passwordChangeSuccess) {
      onCloseForgortPassword();
    }
  }, [passwordChangeSuccess]);

  const onCloseForgortPassword = () => {
    setForgotPasswordModal(false);
  };

  const onCloseResendConfirmation = () => {
    setResendConfirmationModal(false);
  };

  const openVeirificationModal = () => {
    // dispatch(setOpenVerifySignUpModal(true));
    setResendConfirmationModal(true);
  };

  const fieldValidator = (value, validations, fieldId) => {
    const { error, errorText } = validate(value, validations);
    setErrorMessage({
      ...errorMessage,
      [fieldId]: "",
    });
    if (error) {
      setErrorMessage({
        ...errorMessage,
        [fieldId]: errorText,
      });
    }
    if (fieldId === "email") setUsername(value);
    else if (fieldId === "password") setPassword(value);
    return error;
  };

  const formValidator = () => {
    const formValue = {
      email: username,
      password: password,
    };
    const fields = ["email", "password"];
    const errors = {};
    let formError = false;
    for (let index = 0; index < fields.length; index++) {
      const { error, errorText } = validate(
        formValue[fields[index]],
        validations[fields[index]]
      );
      if (error) {
        formError = true;
        errors[fields[index]] = errorText;
      } else {
        errors[fields[index]] = "";
      }
    }
    setErrorMessage(errors);
    return formError;
  };

  const handleSubmit = () => {
    const error = formValidator();
    if (error) {
      return;
    }
    dispatch(signIn({ username, password }));
  };

  const SSOListner = (zEvent) => {
    if (zEvent.ctrlKey && (zEvent.key === "l" || zEvent.key === "L")) {
      Auth.federatedSignIn({
        customProvider: process.env.REACT_APP_SAML_IDP_NAME,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", SSOListner);
    return () => {
      document.removeEventListener("keydown", SSOListner);
    };
  }, []);

  return (
    <>
      <Modal
        id="loginModal"
        open={showLoginModal}
        onClose={() => {
          dispatch(setShowLoginModal(false));
          setUsername("");
          setPassword("");
          setErrorMessage({ ...errorMessage, email: "", password: "" });
          navigate("/");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(15px)",
          background: "rgba(255, 255, 255, 0.2)",
        }}
      >
        <Box sx={style}>
          <>
            <div className="mb-3 back-button d-flex justify-content-end">
              <span
                data-testid="close-btn"
                className="close-button"
                onClick={() => {
                  dispatch(setShowLoginModal(false));
                  setUsername("");
                  setPassword("");
                  setErrorMessage({ ...errorMessage, email: "", password: "" });
                  navigate("/");
                }}
              >
                CLOSE
              </span>
            </div>
            <h3>Login</h3>
            <TextField
              data-testid="emailField"
              label="Email"
              variant="standard"
              value={username}
              error={errorMessage["email"] ? true : false}
              onChange={(e) => {
                fieldValidator(e.target.value, validations["email"], "email");
              }}
              className="flex-grow-1 w-100 mt-3"
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.code === 13) {
                  handleSubmit();
                }
              }}
            />
            <FormHelperText
              data-testid="emailErrorMessage"
              error={errorMessage["email"] ? true : false}
              id="standard-weight-helper-text"
              className="mx-0"
            >
              {errorMessage["email"]}
            </FormHelperText>

            <FormControl
              error={errorMessage["password"] ? true : false}
              // variant="outlined"
              className="w-100 mt-3"
            >
              <InputLabel className="password-label-margin">
                Password
              </InputLabel>
              <Input
                data-testid="passwordField"
                type={showPassword ? "text" : "password"}
                value={password}
                error={errorMessage["password"] ? true : false}
                onChange={(e) => {
                  fieldValidator(
                    e.target.value,
                    validations["password"],
                    "password"
                  );
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
                onBlur={(e) => {
                  fieldValidator(
                    e.target.value,
                    validations["password"],
                    "password"
                  );
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? (
                        <Visibility sx={{ fontSize: 20 }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: 20 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                data-testid="passwordErrorMessage"
                id="standard-weight-helper-text"
                className="mx-0"
              >
                {errorMessage["password"]}
              </FormHelperText>
            </FormControl>

            <Button
              data-testid="submitButton"
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              className="login-button mt-4"
            >
              LOGIN
            </Button>
            <div className="d-flex flex-row justify-content-between justify-content-sm-between justify-content-md-end mt-2">
              <Button
                data-testid="submitButton"
                color="info"
                variant="contained"
                onClick={() => {
                  Auth.federatedSignIn({
                    customProvider: process.env.REACT_APP_SAML_IDP_NAME,
                  });
                }}
                className="mt-4 d-block d-xs-block d-sm-block d-md-none"
              >
                SSO
              </Button>
              <div>
                <div
                  data-testid="forgotPasswordButton"
                  onClick={() => setForgotPasswordModal(true)}
                  className="d-flex justify-content-end mt-3 forgot-password"
                >
                  Forgot Password
                </div>

                <div
                  data-testid="ContinueVerificationButton"
                  className="d-flex justify-content-end mt-1"
                  onClick={openVeirificationModal}
                >
                  <span className="continue-verification">
                    Continue Account Verification
                  </span>
                </div>
              </div>
            </div>
          </>
        </Box>
      </Modal>
      {forgotPasswordModal && (
        <ForgotPassword onClose={onCloseForgortPassword} />
      )}
      {resendConfirmationModal && (
        <ResendConfirmation onClose={onCloseResendConfirmation} />
      )}
      {openVerifySignupModal && <ConfirmSignUp />}
    </>
  );
};

export default LoginModal;
