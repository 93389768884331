import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export const resendConfirmationCode = createAsyncThunk(
  "signUp/resendConfirmationCode",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setMailSent(false));
    const response = await Auth.resendSignUp(body.email);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const resendConfiramtionSlice = createSlice({
  name: "signUp",
  initialState: {
    loading: false,
    error: null,
    mailSent: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setMailSent: (state, action) => {
      state.mailSent = action.payload;
    },
  },
  extraReducers: {
    [resendConfirmationCode.fulfilled]: (state, action) => {
      toast.success(`The Verification Code is sent to your mentioned Email ID`);
      state.loading = false;
      state.mailSent = true;
    },
    [resendConfirmationCode.rejected]: (state, action) => {
      toast.error(`${action.error.message}`);
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setMailSent } = resendConfiramtionSlice.actions;

export default resendConfiramtionSlice.reducer;
