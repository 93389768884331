export const sanctionsList = ["", "PEP", "RCA", "SI", "SAN", "ECR", "SCO"];
export const sanctionsListFullForm = {
  "": "",
  PEP: "Politically Exposed Person",
  RCA: "Relatives and Close Associates",
  SI: "Special Interest",
  SAN: "Sanctions Lists ",
  ECR: "Enhanced Country Risk",
  SCO: "Sanctions Control & Ownership",
};

export const generateSanctionFilters = (list) => {
  if (!list || list.size === 0) return {};

  let filters = {};

  list.forEach((value) => {
    let filter_array = (value["lookupEntityFilters"] + "").split("");
    filter_array.forEach((filter, index) => {
      if (index !== 0 && filter === "1") {
        filters[sanctionsList[index]] = true;
      }
    });
  });

  let sortedArray = Object.keys(filters).sort((a, b) => {
    if (a === "SAN") return -1;
    if (b === "SAN") return 1;
    return 0;
  });

  let finalFilter = {};

  sortedArray.forEach((e) => {
    finalFilter[e] = filters[e];
  });
  return finalFilter;
};

export const filterBySanctions = (filters, data) => {
  let matchArray = data["matchArray"];
  matchArray = matchArray.filter((element) => {
    let isValid = false;
    let element_filter = getSanctionFilter(element["lookupEntityFilters"]);
    let filter_keys = Object.keys(filters);
    for (let i = 0; i < filter_keys.length; i++) {
      if (
        filters[filter_keys[i]] === true &&
        element_filter[filter_keys[i]] === true
      ) {
        isValid = true;
      }
    }
    return isValid;
  });
  return { ...data, matchArray: matchArray };
};

export const getSanctionFilter = (str) => {
  let filters = {};
  let filter_array = (str + "").split("");
  filter_array.forEach((filter, index) => {
    if (index !== 0 && filter === "1") {
      filters[sanctionsList[index]] = true;
    }
  });

  let sortedArray = Object.keys(filters).sort((a, b) => {
    if (a === "SAN") return -1;
    if (b === "SAN") return 1;
    return 0;
  });

  let finalFilter = {};

  sortedArray.forEach((e) => {
    finalFilter[e] = filters[e];
  });

  return finalFilter;
};
