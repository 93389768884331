import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginRedux";
import searchReducer from "./searchRedux";
import profileReducer from "./profileRedux";
import createBatchReducer from "./createBatch";
import batchListReducer from "./batchList";
import batchSummarySlice from "./batchSummary";
import signUpReducer from "./signupRedux";
import forgotPasswordReducer from "./forgotPassword";
import batchResultReducer from "./batchResultRedux";
import resendConfirmationReducer from "./resendConfirmationRedux";
import metaDataReducer from "./metaDataRedux";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    search: searchReducer,
    profile: profileReducer,
    createBatch: createBatchReducer,
    batchList: batchListReducer,
    batchSummary: batchSummarySlice,
    signUp: signUpReducer,
    forgotPassword: forgotPasswordReducer,
    batchResult: batchResultReducer,
    resendConfirmation: resendConfirmationReducer,
    metaData: metaDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
