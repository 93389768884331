/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect } from "react";

import "./termsAndConditionPopup.scss";

const TermsAndConditionPopup = (props) => {
  const { modalOpen, handleClose } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    bgcolor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  return (
    <>
      <Modal
        id="termsAndConditionPopup"
        open={modalOpen}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mb-3 d-flex justify-content-between">
            <div className="px-4 terms-and-condition-title">
              FACCTVIEW<sup>TM</sup> OPEN ACCESS USAGE AGREEMENT
            </div>
            <div style={{ marginRight: "20px" }}>
              {" "}
              <ClearIcon
                sx={{
                  color: "#000",
                  opacity: "58%",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              />
            </div>
          </div>
          <div className="px-4 terms-and-condition-container">
            <p>
              This&nbsp;<strong>OPEN ACCESS {" "}</strong>
              <strong>USAGE AGREEMENT</strong>&nbsp;(the “Agreement”) is dated
              14<sup>th</sup>&nbsp;October 2022 (the “Effective Date”) between
              you (“User”) and Facctum Solutions Limited, (“Company”).
            </p>
            <ol>
              <li>
                WHEREAS, Company is in the business of providing Sanctions
                Screening Software as an open-access service (“Service”) on the
                internet.
              </li>
              <li>
                WHEREAS, User wishes to use this Service from the Company to
                evaluate for/in their business operation, for which purpose User
                will either directly access the Service on Company’s website or
                avail of the Application Program Interfaces (“APIs”) provided by
                the Company as a means of accessing the Company’s Service. User
                may build their own interfaces to connect with this Service.
                These interfaces might also be built using third party software
                (“Third Party Software”)
              </li>
            </ol>
            <p>
              NOW THEREFORE, in consideration of the foregoing recitals, the
              mutual promises, agreements and covenants set forth herein, and
              other good and valuable consideration, the receipt and sufficiency
              of which are hereby acknowledged, the parties agree as follows:
            </p>
            <p>
              1)&nbsp;<strong>INTERFACE DEVELOPMENT &amp; CONFIGURATION</strong>
              .
            </p>
            <p>
              (a)&nbsp;Company and User will work with each other in good faith
              to configure the API with respect to User’s system. The software
              permitting the above-referenced interconnectivity is referred to
              herein as the “User Interface.” User acknowledges that all code
              for the API was written exclusively by Company and that the API
              constitutes Company Intellectual Property (as defined in Section
              4(e)). Company acknowledges that all code for the User Interface
              was written exclusively by the User and that the User Interface
              constitutes User Intellectual Property (as defined in Section
              4(f)).
            </p>
            <p>
              (b)&nbsp;Following completion of the User Interface, both Company
              and User shall subject the User Interface to such operational and
              other tests (collectively, “Acceptance Tests”) as Company and User
              may deem reasonably necessary to determine whether the User
              Interface meets the parties’ respective operational criteria. The
              User Interface shall not be put into commercial use until each
              party has accepted the User Interface. For purposes hereof,
              “accepted” shall mean each party’s written notification to the
              other party that the User Interface has passed all Acceptance
              Tests.
            </p>
            <p>
              (c)&nbsp;Company and User each retain the right at all times to
              make any changes in their respective systems that they may deem
              necessary or desirable.
            </p>
            <p>
              (d)&nbsp;If any change to either the Company’s API or to the
              User’s System results in required modifications to the other
              party’s system to avoid interruption in the User Interface, the
              parties agree to work in good faith, each at its own expense, to
              modify their respective systems to avoid or minimize any such
              interruption; provided, however,
            </p>
            <p>
              that neither party shall be obligated to make any material capital
              expenditure to modify or upgrade its system to be compatible with
              modifications to the other party’s system and, in lieu of making
              any such capital expenditure, may elect to terminate this
              Agreement.
            </p>
            <p>&nbsp;(e)&nbsp;The User Interface will not be co-branded.</p>
            <p>
              <strong>2) LICENSE AND INTELLECTUAL PROPERTY.</strong>
            </p>
            <p>
              (a)&nbsp;License to use Service and API. Subject to the terms and
              conditions of this Agreement, Company hereby grants to User a
              non-exclusive, non-transferable license to use the Service
              (“License to Use Service”) and the APIs (“License to Use API”)
              during the term of this Agreement for the sole purpose of enabling
              the User or User system to interact with the Company’s system. The
              License to Use Service and License to use API shall automatically
              terminate upon any termination of this Agreement.
            </p>
            <p>
              (b)&nbsp;License to Use User Interface. Subject to the terms and
              conditions of this Agreement, User hereby grants to Company a
              non-exclusive, non-transferable license to use the User Interface
              during the term of this Agreement for the sole purpose of enabling
              the User System to interact with the Company’s System through the
              User Interface and API (“License to Use User Interface”). The
              License to Use User Interface shall automatically terminate upon
              any termination of this Agreement.
            </p>
            <p>(c)&nbsp;License to Use API: Restrictions.</p>
            <ul>
              <li>User shall not use the API:</li>
            </ul>
            <ul>
              <li>
                in any way other than as expressly permitted or granted under
                this Agreement;&nbsp;and
              </li>
            </ul>
            <ul>
              <li>
                alone or in conjunction with the User System in any way that
                results in or could result in any security breach with respect
                to the Company or a violation of any applicable law or
                regulation or Company’s informational technology security
                policies, as published by Company from time to time.
              </li>
            </ul>
            <ul>
              <li>
                The License to Use API shall not include the right of User to
                sublicense the API to any third party.
              </li>
            </ul>
            <p>(d) License to Use User Interface: Restrictions.</p>
            <ul>
              <li>Company shall not use the User Interface:</li>
            </ul>
            <ul>
              <li>
                in any way other than as expressly permitted or granted under
                this Agreement; and
              </li>
            </ul>
            <ul>
              <li>
                alone or in conjunction with the Company System in any way that
                results in&nbsp;any security breach with respect to the User or
                a violation of any applicable law or regulation or User’s
                informational technology security policies, as published by User
                from time to time and provided to Company;
              </li>
            </ul>
            <ul>
              <li>
                The License to Use User Interface shall not include the right of
                Company to sublicense the User Interface to any third party.
              </li>
            </ul>
            <p>
              (e)&nbsp;Except for the License to Use API, User acknowledges and
              agrees that it acquires no license to the Company’s System and
              that Company own all right, title and interest in and to the
              Company’s System, its data and any modifications, alterations,
              translations or derivative works relating to the Company’s System,
              including, but not limited to, the API and any code written by
              Company in connection with the User Interface (collectively, the
              “Company Intellectual Property”).
            </p>
            <p>
              (f)&nbsp;Except for the License to Use User Interface, Company
              acknowledges and agrees that it acquires no license to the User
              System and that User owns all right, title, and interest in and to
              the User System, its data and any modifications, alterations,
              translations or derivative works relating to the User System,
              including, but not limited to, any code written by User in
              connection with the User Interface (collectively, the “User
              Intellectual Property”).
            </p>
            <p>
              <strong>3) CUSTOMER SUPPORT.</strong>
            </p>
            <p>
              Neither party shall have any customer support obligations in
              connection with the User Interface, provided, however, the parties
              shall provide customary and usual support of their respective
              systems and their other products and services that may be
              delivered in connection with the User Interface.
            </p>
            <p>
              <strong>4) DISCLAIMER OF WARRANTIES.</strong>
            </p>
            <p>
              EXCEPT FOR ANY EXPRESS WARRANTIES SET FORTH IN THE AGREEMENT, EACH
              PARTY HEREBY DISCLAIMS ALL WARRANTIES REGARDING THE COMPANY
              INTELLECTUAL PROPERTY, THE USER SYSTEM AND THE USER INTELLECTUAL
              PROPERTY, THE API, THE USER INTERFACE, THEIR RESPECTIVE
              CONFIDENTIAL INFORMATION AND THEIR RESPECTIVE BUSINESSES, AND ANY
              THIRD PARTY SOFTWARE OR HARDWARE, INCLUDING, WITHOUT LIMITATION,
              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE, NON-INFRINGEMENT, AS WELL AS ANY WARRANTIES ARISING OUT
              OF COURSE OF USAGE.
            </p>
            <p>
              <strong>5) LIMITATION OF LIABILITY.</strong>
            </p>
            <p>
              EXCEPT FOR ANY EXPRESS CLAIMS SET FORTH IN THIS AGREEMENT OR
              DAMAGES RESULTING FROM A PARTY’S GROSS NEGLIGENCE OR WILFUL
              MISCONDUCT, NEITHER PARTY WILL BE LIABLE TO THE OTHER FOR ANY
              INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
              OR INCIDENTAL LOSSES OR DAMAGES OF ANY KIND, INCLUDING, BUT NOT
              LIMITED TO, LOST PROFITS, LOST SAVINGS OR LOSS OF USE OF
              FACILITIES OR EQUIPMENT, REGARDLESS OF WHETHER ARISING FROM BREACH
              OF CONTRACT, WARRANTY, TORT, STRICT LIABILITY OR OTHERWISE, EVEN
              IF ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE, OR IF SUCH
              LOSS OR DAMAGE COULD HAVE BEEN REASONABLY FORESEEN.
            </p>
            <p>
              NEITHER PARTY SHALL BE LIABLE FOR ANY FAILURE OR DELAY IN ITS
              PERFORMANCE DUE TO CIRCUMSTANCES BEYOND ITS CONTROL, INCLUDING,
              BUT NOT LIMITED TO, ACTS OF CIVIL OR MILITARY AUTHORITY, NATIONAL
              EMERGENCIES, TERRORISM, LABOUR DIFFICULTIES, FIRE, FLOOD OR
              CATASTROPHE, ACTS OF GOD, INSURRECTION, WAR, RIOTS OR FAILURE
              BEYOND ITS REASONABLE CONTROL OF TRANSPORTATION, POWER SUPPLY,
              TELEPHONE OR OTHER COMMUNICATIONS LINES.
            </p>
            <p>
              <strong>6) INDEMNIFICATION.</strong>
            </p>
            <p>
              User hereby agrees to defend, indemnify, and hold Company harmless
              from and against any and all losses to which User may become
              subject, insofar as such losses arise out of or are based upon (i)
              any breach or violation by User of the terms of any of the
              licenses granted to User under this Agreement; (ii) any breach or
              violation by Vendor of any applicable laws and regulations;
            </p>
            <p>
              <strong>7) OPEN ACCESS SERVICE.</strong>
            </p>
            <p>
              The Service is available to all Company website visitors (Users).
              Company is not responsible if information made available on this
              Service is not accurate, complete, or current. This Service is
              provided for general information only and should not be relied
              upon or used as the sole basis for making business decisions
              without consulting other primary, more accurate, more complete, or
              more timely sources of information. Any reliance on the material
              on this Service is at User’s own risk. This Service may contain
              historical information that is not current and is provided for
              User’s reference only. Company reserves the right to modify the
              contents of this site at any time, without obligation to update
              any information on the Company website.
            </p>
            <p>
              User expressly agrees that the use or inability to use this
              Service is at User’s sole risk. The Service is provided ‘as is’
              and ‘as available’ for User’s use, without any representation,
              warranties, or conditions of any kind, either express or implied,
              including but not limited to all implied warranties or conditions
              of merchantability, merchantable quality, fitness for a particular
              purpose, durability, title, and non-infringement. Company does not
              guarantee, represent, or warrant that the Service will be
              uninterrupted, timely, secure, and error-free. Further Company
              does not represent or warrant that the results that may be
              obtained from the use of the Service will be accurate, reliable,
              complete, and current. Company undertakes no obligation to update,
              amend or clarify information or data in the Service.
            </p>
            <p>
              IN NO CASE SHALL COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES,
              AFFILIATES, AGENTS, CONTRACTORS, INTERNS, SUPPLIERS, SERVICE
              PROVIDERS OR LICENSORS BE LIABLE FOR ANY INJURY, LOSS, CLAIM, OR
              ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR
              CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION
              LOST PROFITS, LOST REVENUE, LOST SAVINGS, LOSS OF DATA,
              REPLACEMENT COSTS, OR ANY SIMILAR DAMAGES, WHETHER BASED IN
              CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
              OTHERWISE, ARISING FROM USER’S USE OF THE SERVICE OR FOR ANY OTHER
              CLAIM RELATED IN ANY WAY TO USER’S USE OF THE SERVICE, INCLUDING,
              BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY CONTENT, OR ANY
              LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
              SERVICE OR ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
              AVAILABLE VIA THE SERVICE, EVEN IF ADVISED OF THIS POSSIBILITY.
            </p>
            <p>
              <strong>8) WAIVER.</strong>
            </p>
            <p>
              The failure by either party to enforce any provision of the
              Agreement will not constitute a waiver of future enforcement of
              that or any other provision.
            </p>
            <p>
              <strong>9) GOVERNING LAW AND JURISDICTION</strong>
            </p>
            <p>
              This Agreement and any dispute arising from it, whether
              contractual or non-contractual, will be governed by English law
              and subject to the exclusive jurisdiction of the English courts.
            </p>
            <p>
              <strong>10) SEVERABILITY.</strong>
            </p>
            <p>
              If for any reason a court of competent jurisdiction finds any
              provision of the Agreement invalid or unenforceable, that
              provision will be enforced to the maximum extent permissible, and
              the other provisions of the Agreement will remain in full force
              and effect.
            </p>
            <p>
              <strong>11) ASSIGNMENT.</strong>
            </p>
            <p>
              User shall not assign its rights or delegate its duties hereunder
              without the prior written consent of the Company, such consent not
              to be unreasonably withheld or delayed.
            </p>
            <p>
              <strong>12) NO RULE OF STRICT CONSTRUCTION.</strong>
            </p>
            <p>
              The parties acknowledge that the provisions and language of the
              Agreement have been understood by both parties and specifically
              agree that no provision of the Agreement shall be construed
              against a party by reason of such party having drafted such
              provision or the Agreement.
            </p>
            <p>
              <strong>13) ENTIRE AGREEMENT.</strong>
            </p>
            <p>
              This Agreement, including Addendum A, constitutes the complete and
              exclusive understanding and agreement between the parties
              regarding its subject matter and supersedes all prior or
              contemporaneous agreements or understandings, written or oral,
              relating to its subject matter. Any waiver, modification, or
              amendment of any provision of this Agreement will be effective
              only if in writing and signed by duly authorized representatives
              of both parties.
            </p>
          </div>
          <div className="d-flex justify-content-end ">
            <Button
              variant="contained"
              className="mt-4 close-terms-and-condition-modal-button"
              size="medium"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TermsAndConditionPopup;
