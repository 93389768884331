/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchInput from "./searchInput/searchInput";
import SearchOutput from "./searchOutput/searchOutput";
import { useNavigate } from "react-router-dom";
import Filter from "./filter/filter";
import "./search.scss";
import Header from "../header/header";
import { Fab, SwipeableDrawer } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const Search = () => {
  const navigate = useNavigate();
  const searchString = useSelector((state) => state.search.searchString);
  const searchId = useSelector((state) => state.search.searchId);
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    if (searchString === "" && searchId === "") {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div
      className="d-flex justify-content-center w-100 "
      style={{ backgroundColor: "#F8FAFB" }}
    >
      <Header />
      <div id="search" className={`page h-100 d-flex searched-background `}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
          onOpen={() => setFilterDrawerOpen(true)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Filter />
        </SwipeableDrawer>
        <div
          className="position-fixed d-md-none"
          style={{ bottom: "1em", right: "1em", zIndex: "999" }}
        >
          <Fab
            color="secondary"
            aria-label="edit"
            onClick={() => setFilterDrawerOpen(true)}
          >
            <FilterAltIcon />
          </Fab>
        </div>
        <div className="d-none d-md-block w-25">
          <Filter />
        </div>
        <div className="flex-grow-1 p-3 d-flex flex-column justify-content-center w-100 w-md-75">
          <SearchInput />
          <SearchOutput />
        </div>
      </div>
    </div>
  );
};

export default Search;
