import { Auth } from "aws-amplify";

export const generateAPIClient = async () => {
  const currentSession = await Auth.currentCredentials();
  return window.apigClientFactory.newClient(
    {
      accessKey: currentSession["accessKeyId"],
      secretKey: currentSession["secretAccessKey"],
      sessionToken: currentSession["sessionToken"],
      region: process.env.REACT_APP_REGION,
    },
    process.env.REACT_APP_API_GATEWAY_BASE
  );
};
