import React from "react";
import { useSelector } from "react-redux";
import noMatch from "../../../../../assets/images/clipart/noMatches.svg";
import matchLimitExceed from "../../../../../assets/images/clipart/matchLimitExceed.svg";

const NoMatch = () => {
  const error = useSelector((state) => state.search.error);
  const showForceLoginErrorMessage = useSelector(
    (state) => state.login.showForceLoginErrorMessage
  );

  return (
    <div
      className={`w-100 flex-grow-1 d-flex flex-column ${
        showForceLoginErrorMessage ? " " : "mt-5 pt-5"
      } align-items-center`}
      data-testid="no-match"
    >
      {!showForceLoginErrorMessage && (
        <img
          src={noMatch}
          alt="facctumLogo"
          style={{ width: "100px", height: "100px" }}
        />
      )}

      {error ? (
        showForceLoginErrorMessage ? (
          <>
            {" "}
            <img src={matchLimitExceed} alt="MatchLimitExceed" />
          </>
        ) :
        <>
          <h3>Some error occured</h3>
          <span className="w-25 text-center" style={{ color: "lightgray" }}>
            <div>&bull; Try again.</div>
            <div>&bull; Contact Technical Support`</div>
          </span>
        </>
      ) : (
        <>
          <h3>No Matching Results</h3>
          <span className="w-25 text-center" style={{ color: "lightgray" }}>
            <div>&bull; Try a different name</div>
            <div>&bull; Reset the filters</div>
          </span>
        </>
      )}
    </div>
  );
};

export default NoMatch;
