/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import logout from "../../../../assets/images/icons/logout.svg";
import { signOut } from "../../../../redux/loginRedux";
import "./userMenu.scss";

const UserMenu = ({ user }) => {
	const dispatch = useDispatch();

	return (
		<>
			<div className="d-flex align-items-center">
				<Tooltip
					title={
						<div className="text-center">
							{user?.attributes?.name} <br /> {user?.attributes?.email}
						</div>
					}
					arrow
					disableInteractive
				>
					<div
						data-testid="tooltip"
						className="avatar d-flex justify-content-center align-items-center me-2"
					>
						<span className="pe-none">{user?.attributes?.name?.charAt(0)}</span>
					</div>
				</Tooltip>

				<div
					data-testid="logoutButton"
					className="d-flex align-items-center logout-button"
					onClick={() => {
						dispatch(signOut());
					}}
				>
					<span className="user-name body-small-bold">&nbsp;Logout</span>
					&nbsp;
					<img src={logout} alt="Logout" />
				</div>
			</div>
			{/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose(() => toast.info("Coming Soon"));
          }}
        >
          <AssignmentIndIcon />
          &nbsp;Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose(() => toast.info("Coming Soon"));
          }}
        >
          <AccountBalanceWalletIcon />
          &nbsp;My account
        </MenuItem>
        {isAuthenticated && batchAccess ? (
          <MenuItem
            onClick={() => {
              handleClose(() => setNavigateTo("/batch"));
            }}
          >
            Go to Batch
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            dispatch(signOut());
            handleClose();
          }}
        >
          <LogoutIcon />
          &nbsp;Logout
        </MenuItem>
      </Menu> */}
		</>
	);
};

export default UserMenu;
