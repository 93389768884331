/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Modal } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect } from "react";

import "./privacyPolicy.scss";

const PrivacyPolicyPopup = (props) => {
  const { modalOpen, handleClose } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    bgcolor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  return (
    <>
      <Modal
        id="privacyPolicyPopup"
        open={modalOpen}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mb-3 d-flex justify-content-between">
            <div className="px-4 privacy-policy-title">Privacy Policy</div>
            <div style={{ marginRight: "20px" }}>
              {" "}
              <ClearIcon
                sx={{
                  color: "#000",
                  opacity: "58%",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              />
            </div>
          </div>
          <div className="px-4 privacy-policy-container">
            <p>
              <strong>We respect your privacy</strong>
            </p>
            <p>
              Facctum Solutions Limited respects your right to privacy and is
              committed to safeguarding the privacy of our customers and website
              visitors. This policy sets out how we collect and treat your
              personal information.
            </p>
            <p>
              {" "}
              We adhere to the UK General Data Protection Regulation (UK GDPR),
              tailored by the Data Protection Act 2018. “Personal information”
              is information we hold which is identifiable as being about you.
              This includes information such as your name, email address,
              identification number, or any other type of information that can
              reasonably identify an individual, either directly or indirectly.
            </p>
            <p>
              {" "}
              You may contact us in writing at our email address:
              privacy@facctum.com for further information about this Privacy
              Policy.
            </p>
            <p>
              <strong>What personal information is collected?</strong>{" "}
            </p>
            <p>
              {" "}
              Facctum Solutions Limited will, from time to time, receive and
              store personal information that you submit to our website, provide
              to us directly, or give to us in other forms. You may provide
              basic information such as your name, phone number, address, and
              email address to enable us to send you information, provide
              updates and process your product or service order.
            </p>
            <p>
              {" "}
              We may collect additional information at other times, including
              but not limited to, when you provide feedback, when you provide
              information about your personal or business affairs, change your
              content or email preference, respond to surveys and/or promotions,
              provide financial or credit card information, or communicate with
              our customer support.{" "}
            </p>
            <p>
              Additionally, we may also collect any other information you
              provide while interacting with us.{" "}
            </p>
            <p>
              <strong>How we collect your personal information</strong>
            </p>{" "}
            <p>
              Facctum Solutions Limited collects personal information from you
              in a variety of ways, including when you interact with us
              electronically or in person, when you access our website and when
              we engage in business activities with you. We may receive personal
              information from third parties. If we do, we will protect it as
              set out in this Privacy Policy. By providing us with personal
              information, you consent to the supply of that information subject
              to the terms of this Privacy Policy. How we use your personal
              information Facctum Solutions Limited may use personal information
              collected from you to provide you with information about our
              products or services. We may also make you aware of new and
              additional products, services, and opportunities available to you.
            </p>
            <p>
              Facctum Solutions Limited will use personal information only for
              the purposes that you consent to. This may include to:
            </p>
            <ol>
              <li>
                provide you with products and services during the usual course
                of our business activities;
              </li>
              <li>provide administer our business activities;</li>
              <li>
                provide you with products and services during the usual course
                of our business activities;
              </li>
              <li>you with information about our products and services; </li>
              <li>
                {" "}
                communicate with you by a variety of measures including, but not
                limited to, by telephone, email, SMS or mail; and{" "}
              </li>

              <li>investigate any complaints. </li>
            </ol>
            <p>
              {" "}
              If you withhold your personal information, it may not be possible
              for us to provide you with our products and services or for you to
              fully access our website. We may disclose your personal
              information to comply with a legal requirement, such as a law,
              regulation, court order, subpoena, warrant, legal proceedings or
              in response to a law enforcement agency request.
            </p>
            <p>
              {" "}
              If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases.
            </p>
            <p>
              <strong>Disclosure of your personal information</strong>
            </p>
            <p>
              {" "}
              Facctum Solutions Limited may disclose your personal information
              to any of our employees, officers, insurers, professional
              advisers, agents, suppliers, or subcontractors as far as
              reasonably necessary for the purposes set out in this privacy
              policy.{" "}
            </p>
            <p>
              If we do disclose your personal information to a third party, we
              will protect it in accordance with this privacy policy.
            </p>
            <p>
              <strong>
                {" "}
                General Data Protection Regulation (GDPR) for the European Union
                (EU)
              </strong>
            </p>
            <p>
              Facctum Solutions Limited will comply with the principles of data
              protection set out in the GDPR for the purpose of fairness,
              transparency and lawful data collection and use.
            </p>
            <p>
              {" "}
              We process your personal information as a Processor and/or to the
              extent that we are a Controller as defined in the GDPR.
            </p>
            <p>
              {" "}
              We must establish a lawful basis for processing your personal
              information. The legal basis for which we collect your personal
              information depends on the data that we collect and how we use it.
            </p>
            <p>
              We will only collect your personal information with your express
              consent for a specific purpose and any data collected will be to
              the extent necessary and not excessive for its purpose. We will
              keep your data safe and secure.{" "}
            </p>
            <p>
              We will also process your personal information if it is necessary
              for our legitimate interests, or to fulfil a contractual or legal
              obligation.
            </p>
            <p>
              {" "}
              We process your personal information if it is necessary to protect
              your life or in a medical situation, it is necessary to carry out
              a public function, a task of public interest or if the function
              has a clear basis in law.{" "}
            </p>
            <p>
              We do not collect or process any personal information from you
              that is considered “Sensitive Personal Information” under the
              GDPR, such as personal information relating to your sexual
              orientation or ethnic origin unless we have obtained your explicit
              consent, or if it is being collected subject to and in accordance
              with the GDPR.{" "}
            </p>
            <p>
              You must not provide us with your personal information if you are
              under the age of 16 without the consent of your parent or someone
              who has parental authority for you. We do not knowingly collect or
              process the personal information of children.
            </p>
            <p>
              {" "}
              <strong>Your rights under the GDPR</strong>
            </p>
            <p>
              If you are an individual residing in the EU, you have certain
              rights as to how your personal information is obtained and used.
              Facctum Solutions Limited complies with your rights under the GDPR
              as to how your personal information is used and controlled if you
              are an individual residing in the EU
            </p>
            <p>
              {" "}
              Except as otherwise provided in the GDPR, you have the following
              rights:
            </p>
            <ol>
              <li>
                to be informed how your personal information is being used;
              </li>
              <li>
                {" "}
                access your personal information (we will provide you with a
                free copy of it);
              </li>
              <li>
                to correct your personal information if it is inaccurate or
                incomplete;
              </li>
              <li>
                to delete your personal information (also known as “the right to
                be forgotten”);
              </li>
              <li> to restrict processing of your personal information; </li>
              <li>
                to retain and reuse your personal information for your own
                purposes;
              </li>
              <li>to object to your personal information being used; and</li>
              <li>
                to object against automated decision making and profiling.
              </li>

              <li>
                Please contact us at any time to exercise your rights under the
                GDPR at the contact details in this Privacy Policy.{" "}
              </li>
              <li>
                We may ask you to verify your identity before acting on any of
                your requests.
              </li>
            </ol>
            <p>
              {" "}
              <strong>Hosting and International Data Transfers</strong>
            </p>
            <p>
              {" "}
              Information that we collect will, from time to time be stored,
              processed in, or transferred between parties or sites located in
              the the United Kingdom and/or the European Union.
            </p>
            <p>
              {" "}
              We and our other group companies have offices and/or facilities in
              the United Kingdom and in India. Transfers to each of these
              countries will be protected by appropriate safeguards, these
              include one or more of the following: the use of standard data
              protection clauses adopted or approved by the European Commission
              which you can obtain from the European Commission Website; the use
              of binding corporate rules, a copy of which you can obtain from
              Facctum Solutions Limited’s Data Protection
              Officer.(privacy@facctum.com)
            </p>
            <p>
              {" "}
              The hosting facilities for our website are situated in the United
              Kingdom. Transfers to each of these Countries will be protected by
              appropriate safeguards, these include one or more of the
              following: the use of standard data protection clauses adopted or
              approved by the European Commission which you can obtain from the
              European Commission Website; the use of binding corporate rules, a
              copy of which you can obtain from Facctum Solutions Limited’s Data
              Protection Officer. (privacy@facctum.com)
            </p>
            <p>
              {" "}
              Our Suppliers and Contractors are situated in the United Kingdom
              and in India. Transfers to each of these Countries will be
              protected by appropriate safeguards, these include one or more of
              the following: the use of standard data protection clauses adopted
              or approved by the European Commission which you can obtain from
              the European Commission Website; the use of binding corporate
              rules, a copy of which you can obtain from Facctum Solutions
              Limited’s Data Protection Officer. (privacy@facctum.com)
            </p>
            <p>
              {" "}
              You acknowledge that personal data that you submit for publication
              through our website or services may be available, via the
              internet, around the world. We cannot prevent the use (or misuse)
              of such personal data by others.{" "}
            </p>
            <p>
              <strong>Security of your personal information</strong>
            </p>
            <p>
              {" "}
              Facctum Solutions Limited is committed to ensuring that the
              information you provide to us is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic, and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification, and disclosure.
            </p>
            <p>
              {" "}
              Where we employ data processors to process personal information on
              our behalf, we only do so on the basis that such data processors
              comply with the requirements under the GDPR and that have adequate
              technical measures in place to protect personal information
              against unauthorised use, loss, and theft.
            </p>
            <p>
              {" "}
              The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information that
              we collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.
            </p>
            <p>
              <strong>Access to your personal information</strong>
            </p>
            <p>
              {" "}
              You may request details of personal information that we hold about
              you in accordance with the provisions of the UK General Data
              Protection Regulation (UK GDPR), tailored by the Data Protection
              Act 2018 and, to the extent applicable, the EU GDPR. If you would
              like a copy of the information which we hold about you or believe
              that any information we hold on you is inaccurate, out of date,
              incomplete, irrelevant, or misleading, please email us at
              privacy@facctum.com.{" "}
            </p>
            <p>
              We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act or any other applicable law.
            </p>
            <p>
              <strong>Complaints about privacy</strong>
            </p>
            <p>
              {" "}
              If you have any complaints about our privacy practices, please
              feel free to send in details of your complaints to
              privacy@facctum.com. We take complaints very seriously and will
              respond shortly after receiving written notice of your complaint.
            </p>
            <p>
              <strong>Changes to Privacy Policy</strong>
            </p>{" "}
            <p>
              Please be aware that we may change this Privacy Policy in the
              future. We may modify this Policy at any time, in our sole
              discretion and all modifications will be effective immediately
              upon our posting of the modifications on our website or notice
              board. Please check back from time to time to review our Privacy
              Policy.
            </p>
            <p>
              {" "}
              <strong>Website</strong>
            </p>
            <p> When you visit our website </p>
            <p>
              When you come to our website (https://facctum.com/), we may
              collect certain information such as browser type, operating
              system, website visited immediately before coming to our site,
              etc. This information is used in an aggregated manner to analyse
              how people use our site, such that we can improve our service.
            </p>{" "}
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              We may from time-to-time use cookies on our website. Cookies are
              very small files which a website uses to identify you when you
              come back to the site and to store details about your use of the
              site. Cookies are not malicious programs that access or damage
              your computer. Most web browsers automatically accept cookies, but
              you can choose to reject cookies by changing your browser
              settings. However, this may prevent you from taking full advantage
              of our website. Our website may from time-to-time use cookies to
              analyses website traffic and help us provide a better website
              visitor experience. In addition, cookies may be used to serve
              relevant ads to website visitors through third party services such
              as Google AdWords. These ads may appear on this website or other
              websites you visit.
            </p>
            <p>
              <strong>Third party sites</strong>
            </p>{" "}
            <p>
              {" "}
              Our site may from time to time have links to other websites not
              owned or controlled by us. These links are meant for your
              convenience only. Links to third party websites do not constitute
              sponsorship or endorsement or approval of these websites. Please
              be aware that Facctum Solutions Limited is not responsible for the
              privacy practises of other such websites. We encourage our users
              to be aware, when they leave our website, to read the privacy
              statements of each and every website that collects personal
              identifiable information.
            </p>
          </div>
          <div className="d-flex justify-content-end ">
            <Button
              variant="contained"
              className="mt-4 close-privacy-policy-modal-button"
              size="medium"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyPolicyPopup;
