// return {error: true/false, errorMessage: ""}
export const validate = (value, valiations) => {
  const validationLength = valiations?.length;
  if (!validationLength) {
    return { error: false, errorText: null };
  }

  for (let index = 0; index < validationLength; index++) {
    const regex = new RegExp(valiations[index]["regex"]);
    if (!regex.test(value)) {
      return { error: true, errorText: valiations[index]["message"] };
    }
  }

  return { error: false, errorText: null };
};
