/* eslint-disable react-hooks/exhaustive-deps */
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./app.scss";

import { useDispatch, useSelector } from "react-redux";
import Footer from "./components/functional/footer/footer";
import Home from "./components/functional/home/home";
import SpinnerBlocker from "./components/functional/loader/spinnerBlocker/spinnerBlocker";
import LoginPopup from "./components/functional/login/loginPopup/loginPopup";
import { ProtectedRoute } from "./components/utility/protectedRoute/protectedRoute";
import { checkSessionForUser } from "./redux/loginRedux";
import { getSanctionList } from "./redux/metaDataRedux";
import Search from "./components/functional/search/search";

// const Search = React.lazy(() =>
//   import("./components/functional/search/search")
// );

const ContactUs = React.lazy(() =>
  import("./components/functional/contactUs/contactUs")
);

const BatchLoginPage = React.lazy(() =>
  import("./components/functional/login/batchLoginPage/batchLoginPage")
);
const BatchSummary = React.lazy(() =>
  import("./components/functional/batch/batchSummary/batchSummary")
);
const BatchResult = React.lazy(() =>
  import("./components/functional/batch/batchResult/batchResult")
);

const Batch = React.lazy(() => import("./components/functional/batch/batch"));
const SignUp = React.lazy(() =>
  import("./components/functional/signUp/signUp")
);
const Profile = React.lazy(() =>
  import("./components/functional/profile/profile")
);

const App = () => {
  const dispatch = useDispatch();
  const sessionLoginAttempt = useSelector(
    (state) => state.login.sessionLoginAttempt
  );
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // User has switched back to the tab
  const onFocus = () => {
    dispatch(checkSessionForUser());
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    dispatch(checkSessionForUser());
  };

  const WindowFocusHandler = () => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        aws_mandatory_sign_in: "false",
      },
      // Temporarly hardcoded - will make dynamic as we create Appsync in staging
      API: {
        aws_appsync_graphqlEndpoint:
          "https://fib4i2pz7fbzxnzfcrruwockni.appsync-api.eu-west-2.amazonaws.com/graphql",
        aws_appsync_region: process.env.REACT_APP_REGION,
        aws_appsync_authenticationType: "API_KEY",
        aws_appsync_apiKey: "da2-naaoqdxtbndgtilbhmjwei4bse",
      },
      oauth: {
        domain: process.env.REACT_APP_DOMAIN_URL,
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        responseType: "token",
        clientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        redirectSignIn: window.location,
        redirectSignOut: process.env.REACT_APP_LOGIN_REDIRECT,
      },
    });
    console.log(process.env.REACT_APP_LOGIN_REDIRECT, process.env);
    dispatch(checkSessionForUser());
    dispatch(getSanctionList());
    WindowFocusHandler();
  }, []);

  return (
    <>
      {" "}
      {sessionLoginAttempt ? (
        <div
          id="applicationContainer"
          className="w-100 h-100"
          data-testid="applicationContainer"
        >
          <React.Suspense fallback={<SpinnerBlocker />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Home />} />
              <Route
                path="/signup"
                element={
                  isAuthenticated ? <Navigate replace to="/home" /> : <SignUp />
                }
              />
              <Route path="/search" element={<Search />} />
              <Route path="/batchlogin" element={<BatchLoginPage />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute redirectTo="/">
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/batch"
                element={
                  <ProtectedRoute redirectTo="/batchlogin">
                    <Batch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/batch/summary"
                element={
                  <ProtectedRoute redirectTo="/batchlogin">
                    <BatchSummary />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/batch/result"
                element={
                  <ProtectedRoute redirectTo="/batchlogin">
                    <BatchResult />
                  </ProtectedRoute>
                }
              />
            </Routes>

            <Footer />
            <LoginPopup />
          </React.Suspense>
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      ) : (
        <SpinnerBlocker />
      )}{" "}
    </>
  );
};

export default App;
