import React, { useRef, useEffect, useState } from 'react';
import Tooltip from "@mui/material/Tooltip";
import "./OverFlowTip.scss"
const OverflowTip = props => {
  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={props.value}
      disableInteractive
      disableHoverListener={!hoverStatus}
    >
      <div
        ref={textElementRef}
       className={`${props.className} elipsis`}
       data-testid="textElement"
      >
        {props.value}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;