import React from "react";

const SearchPlaceholder = () => {
	const generatePlaceholders = () => {
		let output = [],
			repeate = 4;

		while (--repeate) {
			output.push(
				<div
					className="ph-item m-0 mb-2"
					key={`sp-${repeate}`}
					style={{ opacity: repeate * 0.35 }}
				>
					<div className="ph-col-12">
						<div className="ph-avatar" style={{ width: "10px" }}></div>
						<div className="ph-row">
							<div className="ph-col-6 big"></div>
							<div className="ph-col-4 empty big"></div>
							<div className="ph-col-2 big"></div>
							<div className="ph-col-4"></div>
							<div className="ph-col-8 empty"></div>
							<div className="ph-col-6"></div>
							<div className="ph-col-6 empty"></div>
							<div className="ph-col-12"></div>
						</div>
					</div>
				</div>
			);
		}

		return output;
	};

	return <div className="w-100" data-testid="search-placeholder">{generatePlaceholders()}</div>;
};


export default SearchPlaceholder