import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export const forgotPassword = createAsyncThunk(
  "forgotPass/forgotPassword",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setEmail(body.email));
    const response = await Auth.forgotPassword(body.email);
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const forgotPasswordSubmit = createAsyncThunk(
  "forgotPass/forgotPasswordSubmit",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await Auth.forgotPasswordSubmit(body.email, body.code, body.newPasswordConfirm);
    thunkAPI.dispatch(setLoading(false));
    thunkAPI.dispatch(setPasswordChanged(true));
    return response;
  }
);

export const forgotPasswordSlice = createSlice({
  name: "forgotPass",
  initialState: {
    loading: false,
    error: null,
    email: null,
    emailSent: false,
    passwordChanged: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEmailSent:(state, action) =>{
      state.emailSent = action.payload;
    },
    setPasswordChanged:(state, action) =>{
      state.passwordChanged = action.payload;
    },

  },
  extraReducers: {
    [forgotPassword.fulfilled]: (state, action) => {
      toast.success(`A reset code has been sent to your EmailID`);
      state.emailSent = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      toast.error(`${action.error.message}`);
      state.loading = false;
    },
    [forgotPasswordSubmit.fulfilled]: (state, action) => {
      toast.success(`New password has been set.`);
      state.passwordChanged = true;
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      toast.error(`${action.error.message}`);
    },
  },
});

export const { setLoading, setEmail, setEmailSent, setPasswordChanged } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
