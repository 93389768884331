import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Asset import
import "./header.scss";
import facctum from "../../../assets/images/logos/facctumLogo.svg";
import Login from "../login/login";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

const Header = () => {
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const navBarContainerRef = useRef(null);
  // const scrollEventListener = (e) => {
  // 	if (navBarContainerRef && window.scrollY > 64) {
  // 		navBarContainerRef.current.style.height = "45px";
  // 	} else if (navBarContainerRef && window.scrollY < 50) {
  // 		navBarContainerRef.current.style.height = "54px";
  // 	}
  // };

  // useEffect(() => {
  // 	window.addEventListener("scroll", scrollEventListener);
  // 	return () => {
  // 		window.removeEventListener("scroll", scrollEventListener);
  // 	};
  // }, []);

  return (
    <div
      className="w-100 d-flex flex-row align-items-center justify-content-between px-2 px-md-5"
      id="applicationHeader"
      data-testid="header-component"
      ref={navBarContainerRef}
    >
      <div className="d-flex align-items-center facctview-logo-container">
        <div
          data-testid="company-logo"
          id="headerCompanyLogoContainer"
          onClick={() => {
            window.location = window.location.origin.replace("facctview.", "").replace("dev-demo.", "");
          }}
          role="button"
        >
          <img src={facctum} alt="facctumIcon" />
        </div>
      </div>
      <div
        id="headerUserContainer"
        className="flex-grow-1 d-flex justify-content-end"
      >
        {!isAuthenticated && (
          <div className="me-2 d-flex justify-content-center align-items-center">
            <Button
              data-testid="register-button"
              component={Link}
              variant="contained"
              to={`/signup`}
              className="header-register"
              style={{ boxShadow: "none" }}
            >
              REGISTER
            </Button>
          </div>
        )}

        <Login />
      </div>
    </div>
  );
};

export default Header;
